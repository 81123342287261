import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

function load (view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/login',
            name: 'LoginView',
            component: load('LoginView'),
            meta: {
                unguarded: true,
            },
        },
        {
            path: '/',
            name: 'HomeView',
            redirect: '/catalog/catalog',
        },
        {
            path: '/profile',
            name: 'ProfileView',
            component: load('ProfileView'),
            props: {
                breadcrumbElement: {
                    name: 'Profile',
                },
            },
        },
        {
            path: '/password-recover/:RECOVERY_TOKEN',
            name: 'PasswordRecover',
            component: load('PasswordRecoverView'),
            meta: {
                unguarded: true,
            },
        },
        {
            path: '/shop-config',
            props: {
                breadcrumbElement: {
                    name: 'Shop Config',
                },
            },
            component: load('ShopConfigView'),
            children: [
                {
                    path: '',
                    name: 'ShopConfigView',
                    redirect: '/shop-config/general',
                },
                {
                    path: 'finance',
                    name: 'ShopConfigFinance',
                    component: load('shop-config/Finance'),
                    props: {
                        breadcrumbElement: {
                            name: 'finance',
                        },
                    },
                },
                {
                    path: 'orders',
                    name: 'ShopConfigOrders',
                    component: load('shop-config/Orders'),
                    props: {
                        breadcrumbElement: {
                            name: 'orders',
                        },
                    },
                },
                {
                    path: 'content',
                    name: 'ShopConfigContent',
                    component: load('shop-config/Content'),
                    props: {
                        breadcrumbElement: {
                            name: 'content',
                        },
                    },
                },
                {
                    path: 'artwork',
                    name: 'ShopConfigArtwork',
                    component: load('shop-config/Artwork'),
                    props: {
                        breadcrumbElement: {
                            name: 'artwork',
                        },
                    },
                },
                {
                    path: 'general',
                    name: 'ShopConfigGeneral',
                    component: load('shop-config/General'),
                    props: {
                        breadcrumbElement: {
                            name: 'general',
                        },
                    },
                },
                {
                    path: 'shipment',
                    name: 'ShopConfigShipment',
                    component: load('shop-config/Shipment'),
                    props: {
                        breadcrumbElement: {
                            name: 'shipment',
                        },
                    },
                },
                {
                    path: 'customer360',
                    name: 'ShopConfigCustomer360',
                    component: load('shop-config/Customer360'),
                    props: {
                        breadcrumbElement: {
                            name: 'customer360',
                        },
                    },
                },
            ],
        },
        {
            path: '/agents',
            props: {
                breadcrumbElement: {
                    name: 'agents',
                },
            },
            component: load('wrappers/AgentsWrapper'),
            children: [
                {
                    path: '',
                    name: 'AgentsWrapper',
                    component: load('AgentsView'),
                },
                {
                    path: 'add',
                    name: 'AddAgent',
                    component: load('agent/AddAgent'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':agentId',
                    name: 'EditAgent',
                    component: load('agent/EditAgent'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
                {
                    path: ':agentId/permissions',
                    name: 'EditAgentPermissions',
                    component: load('agent/EditAgentPermissions'),
                    props: {
                        breadcrumbElement: {
                            name: 'permissions',
                        },
                    },
                },
            ],
        },
        {
            path: '/customers',
            component: load('wrappers/CustomersWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'customers',
                },
            },
            children: [
                {
                    path: '',
                    name: 'CustomersWrapper',
                    component: load('CustomersView'),
                },
                {
                    path: 'groups',
                    component: load('wrappers/customers/GroupsWrapper'),
                    props: {
                        breadcrumbElement: {
                            name: 'Groups',
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'CustomersGroupsWrapper',
                            component: load('customers/Groups'),
                        },
                        {
                            path: 'add',
                            name: 'AddCustomersGroup',
                            component: load('customers/groups/Add'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add',
                                },
                            },
                        },
                        {
                            path: ':customersGroupId',
                            name: 'EditCustomersGroup',
                            component: load('customers/groups/Edit'),
                            props: {
                                breadcrumbElement: {
                                    name: '',
                                },
                            },
                        },
                    ],
                },
                {
                    path: 'configurations',
                    component: load('wrappers/customers/ConfigurationsWrapper'),
                    props: {
                        breadcrumbElement: {
                            name: 'Account Configurations',
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'CustomersConfigurationsWrapper',
                            component: load('customers/AccountConfigurations'),
                        },
                        {
                            path: 'add',
                            name: 'AddConfiguration',
                            component: load('customers/configurations/Add'),
                            props: {
                                breadcrumbElement: {
                                    name: 'add',
                                },
                            },
                        },
                        {
                            path: ':configurationId',
                            name: 'AccountConfigurationView',
                            component: load('customers/configurations/AccountConfigurationView'),
                            props: {
                                breadcrumbElement: {
                                    name: '',
                                },
                            },
                        },
                    ],
                },
                {
                    path: 'add',
                    name: 'AddCustomer',
                    component: load('customers/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'Add',
                        },
                    },
                },
                {
                    path: ':applicationId/:customerId',
                    name: 'CustomerView',
                    component: load('CustomerView'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/finance',
            component: load('wrappers/FinanceWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'finance',
                },
            },
            children: [
                {
                    path: 'invoices',
                    name: 'InvoicesView',
                    component: load('finance/InvoicesView'),
                    props: {
                        breadcrumbElement: {
                            name: 'invoices',
                        },
                    },
                },
                {
                    path: 'bulk-invoices',
                    name: 'BulkInvoicesView',
                    component: load('finance/BulkInvoicesView'),
                    props: {
                        breadcrumbElement: {
                            name: 'bulk invoices',
                        },
                    },
                },
                {
                    path: 'overpaid-orders',
                    name: 'OverpaidOrdersView',
                    component: load('finance/OverpaidOrdersView'),
                    props: {
                        breadcrumbElement: {
                            name: 'overpaid orders',
                        },
                    },
                },
                {
                    path: 'underpaid-orders',
                    name: 'UnderpaidOrdersView',
                    component: load('finance/UnderpaidOrdersView'),
                    props: {
                        breadcrumbElement: {
                            name: 'underpaid orders',
                        },
                    },
                },
            ],
        },
        {
            path: '/products',
            component: load('wrappers/ProductsWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'products',
                },
            },
            children: [
                {
                    path: '',
                    name: 'ProductsWrapper',
                    component: load('ProductsView'),
                },
                {
                    path: 'categories',
                    name: 'CategoriesView',
                    component: load('products/CategoriesView'),
                    props: {
                        breadcrumbElement: {
                            name: 'categories',
                        },
                    },
                },
                {
                    path: 'attributes',
                    name: 'AttributesView',
                    component: load('products/AttributesView'),
                    props: {
                        breadcrumbElement: {
                            name: 'attributes',
                        },
                    },
                },
                {
                    path: 'catalog',
                    redirect: {
                        name: 'CatalogWrapper',
                    },
                },
            ],
        },
        {
            path: '/catalog',
            component: load('products/catalog/CatalogWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'catalog',
                },
            },
            meta: {
                style: {
                    backgroundColor: '#f8f8f8',
                },
            },
            children: [
                {
                    path: 'catalog',
                    name: 'CatalogWrapper',
                    component: load('products/catalog/CatalogOverview'),
                    props: true,
                },
                {
                    path: 'marketplace',
                    name: 'CatalogWrapperMarket',
                    component: load('products/catalog/CatalogOverview'),
                    props: {
                        overviewType: 'marketplace',
                        breadcrumbElement: {
                            name: 'marketplace',
                        },
                    },
                },
                {
                    path: 'product/:catalogProductId/:productVariantId?',
                    component: load('products/catalog/CatalogProductView'),
                    props: true,
                    children: [
                        {
                            path: '',
                            name: 'CatalogProductView',
                            component: load('products/catalog/CatalogProductEdit'),
                            props: true,
                        },
                    ],
                },
            ],
        },
        {
            path: '/orders',
            component: load('wrappers/OrdersWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'orders',
                },
            },
            children: [
                {
                    path: '',
                    name: 'OrdersWrapper',
                    component: load('OrdersView'),
                },
                {
                    path: 'delays',
                    name: 'OrdersDelay',
                    component: load('OrdersDelayedView'),
                    props: {
                        breadcrumbElement: {
                            name: 'delay',
                        },
                    },
                },
                {
                    path: 'add',
                    name: 'AddOrder',
                    component: load('order/AddOrder'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':applicationId/:accountId/:orderId',
                    component: load('wrappers/OrderWrapper'),
                    props: {
                        breadcrumbElement: {
                            name: 'order',
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'OrderWrapper',
                            component: load('OrderView'),
                        },
                        {
                            path: ':orderLineId',
                            component: load('OrderLineView'),
                            props: {
                                breadcrumbElement: {
                                    name: 'orderline',
                                },
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'OrderLineView',
                                    component: load('orderline/Information'),
                                    props: {
                                        breadcrumbElement: {
                                            name: 'information',
                                        },
                                    },
                                },
                                {
                                    path: 'artwork-check',
                                    name: 'OrderLineArtworkCheck',
                                    component: load('orderline/ArtworkCheck'),
                                    props: {
                                        breadcrumbElement: {
                                            name: 'artwork check',
                                        },
                                    },
                                },
                                {
                                    path: 'history',
                                    name: 'OrderLineHistory',
                                    component: load('orderline/History'),
                                    props: {
                                        breadcrumbElement: {
                                            name: 'history',
                                        },
                                    },
                                },
                                {
                                    path: 'complaints',
                                    name: 'OrderLineComplaints',
                                    component: load('orderline/Complaints'),
                                    props: {
                                        breadcrumbElement: {
                                            name: 'complaints',
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            path: '/artwork',
            name: 'ArtworkWrapper',
            component: load('wrappers/ArtworkWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'artwork',
                },
            },
            children: [
                {
                    path: '',
                    name: 'ArtworkCheck',
                    component: load('artwork/ArtworkCheck'),
                    props: {
                        breadcrumbElement: {
                            name: 'dtp queue',
                        },
                    },
                },
                {
                    path: 'agents-grades',
                    name: 'AgentsArtworkGrades',
                    component: load('artwork/AgentsArtworkGrades'),
                    props: {
                        breadcrumbElement: {
                            name: 'agents grades',
                        },
                    },
                },
            ],
        },
        {
            path: '/roles',
            component: load('wrappers/RolesWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'roles',
                },
            },
            children: [
                {
                    path: '',
                    name: 'RolesWrapper',
                    component: load('Roles'),
                },
                {
                    path: 'add',
                    name: 'AddRole',
                    component: load('roles/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':roleId',
                    name: 'EditRole',
                    component: load('roles/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/permissions',
            component: load('wrappers/PermissionsWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'permissions',
                },
            },
            children: [
                {
                    path: '',
                    name: 'PermissionsWrapper',
                    component: load('Permissions'),
                },
                {
                    path: 'add',
                    name: 'AddPermission',
                    component: load('permissions/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':permissionId',
                    name: 'EditPermission',
                    component: load('permissions/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/smartspy',
            component: load('wrappers/SmartSpyWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'smartspy',
                },
            },
            children: [
                {
                    path: '',
                    name: 'SmartSpyWrapper',
                    component: load('SmartSpy'),
                },
            ],
        },
        {
            path: '/applications',
            component: load('wrappers/ApplicationsWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'applications',
                },
            },
            children: [
                {
                    path: '',
                    name: 'ApplicationsWrapper',
                    component: load('Applications'),
                },
                {
                    path: 'add',
                    name: 'AddApplication',
                    component: load('applications/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':applicationId',
                    name: 'EditApplication',
                    component: load('applications/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/groups',
            component: load('wrappers/GroupsWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'groups',
                },
            },
            children: [
                {
                    path: '',
                    name: 'GroupsWrapper',
                    component: load('Groups'),
                },
                {
                    path: 'add',
                    name: 'AddGroup',
                    component: load('groups/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':groupId',
                    name: 'EditGroup',
                    component: load('groups/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/quotes',
            component: load('wrappers/QuotesWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'quotes',
                },
            },
            children: [
                {
                    path: '',
                    name: 'QuotesWrapper',
                    component: load('Quotes'),
                },
                {
                    path: 'add',
                    name: 'AddQuote',
                    component: load('quotes/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'Add',
                        },
                    },
                },
                {
                    path: ':applicationId/:customerId/:cartId',
                    name: 'EditQuote',
                    component: load('quotes/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/vat-config',
            component: load('wrappers/VatConfigWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'vat config',
                },
            },
            children: [
                {
                    path: 'vat-profiles',
                    name: 'VatConfigWrapper',
                    component: load('vat-config/VatProfiles'),
                    props: {
                        breadcrumbElement: {
                            name: 'vat profiles',
                        },
                    },
                },
                {
                    path: 'company-profiles',
                    name: 'CompanyProfiles',
                    component: load('vat-config/CompanyProfiles'),
                    props: {
                        breadcrumbElement: {
                            name: 'company profiles',
                        },
                    },
                },
            ],
        },
        {
            path: '/complaints/section/:complaintSection?',
            name: 'Complaints',
            component: load('Complaints'),
            props: {
                breadcrumbElement: {
                    name: 'complaints',
                },
            },
        },
        {
            path: '/complaints/merchant-queue',
            name: 'ComplaintsMerchantQueue',
            component: load('Complaints'),
            props: {
                breadcrumbElement: {
                    name: 'complaints',
                },
            },
        },
        {
            path: '/complaints/new-queue',
            name: 'ComplaintsNewQueue',
            component: load('Complaints'),
            props: {
                breadcrumbElement: {
                    name: 'complaints',
                },
            },
        },
        {
            path: '/turnaround-strategies',
            name: 'TurnaroundStrategiesView',
            component: load('TurnaroundStrategiesView'),
            props: {
                breadcrumbElement: {
                    name: 'turnaround strategies',
                },
            },
        },
        {
            path: '/search',
            name: 'SearchResultsView',
            component: load('SearchResultsView'),
            props: {
                breadcrumbElement: {
                    name: 'Search results',
                },
            },
        },
        {
            path: '/product-feeds',
            component: load('wrappers/ProductFeedsWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'product feeds',
                },
            },
            children: [
                {
                    path: '',
                    name: 'ProductFeedsWrapper',
                    component: load('product-feeds/product-feed/ProductFeedOverview'),
                },
                {
                    path: '/product-feed/add',
                    name: 'AddProductFeed',
                    component: load('product-feeds/product-feed/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: '/product-feed/edit/:productFeedId',
                    name: 'EditProductFeed',
                    component: load('product-feeds/product-feed/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: 'edit',
                        },
                    },
                },
                {
                    path: '/product-feed/details/:productFeedId',
                    name: 'ProductFeedDetails',
                    component: load('product-feeds/product-feed/Details'),
                    props: {
                        breadcrumbElement: {
                            name: 'details',
                        },
                    },
                },
                {
                    path: '/configurations',
                    component: load('wrappers/ConfigurationsWrapper'),
                    props: {
                        breadcrumbElement: {
                            name: 'configurations',
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'ConfigurationsWrapper',
                            component: load('product-feeds/configurations/ConfigurationsOverview'),
                        },
                        {
                            path: '/configurations/add',
                            name: 'AddProductFeedConfiguration',
                            component: load('product-feeds/configurations/Add'),
                            props: {
                                breadcrumbElement: {
                                    name: 'add',
                                },
                            },
                        },
                        {
                            path: '/configurations/edit/:configurationId',
                            name: 'EditConfiguration',
                            component: load('product-feeds/configurations/Edit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'edit',
                                },
                            },
                        },
                    ],
                },
                {
                    path: '/fields',
                    component: load('wrappers/FieldsWrapper'),
                    props: {
                        breadcrumbElement: {
                            name: 'fields',
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'FieldsWrapper',
                            component: load('product-feeds/fields/FieldsOverview'),
                        },
                        {
                            path: '/fields/add',
                            name: 'AddField',
                            component: load('product-feeds/fields/Add'),
                            props: {
                                breadcrumbElement: {
                                    name: 'add',
                                },
                            },
                        },
                        {
                            path: '/fields/edit/:fieldId',
                            name: 'EditField',
                            component: load('product-feeds/fields/Edit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'edit',
                                },
                            },
                        },
                    ],
                },
            ],
        },
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
})

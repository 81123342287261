<template>
    <div v-if="isAuthenticated">
        <shop-switcher></shop-switcher>
        <div class="user-text">
            <span v-if="user.fullName" class="button-text">{{user.fullName}}</span>
            <span v-else>{{ user.firstName }} {{user.lastName}}</span>
        </div>
        <mercur-menu>
            <mercur-button class="btn btn-icon btn-primary">
                <span class="text-white"><i class="fas fa-ellipsis-v"></i></span>
            </mercur-button>
            <div slot="dropdown">
                <router-link  :to="{ name: 'ShopConfigView' }">Shop Settings</router-link >
                <router-link :to="{ name: 'ProfileView' }">Profile</router-link >
                <a href="#" @click="logoutUser">Logout</a>
            </div>
        </mercur-menu>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ShopSwitcher from '@/components/elements/ShopSwitcher'

export default {
    name: 'AccountMenu',
    components: { ShopSwitcher },
    methods: {
        ...mapActions('auth', ['logout']),
        logoutUser () {
            this.logout().then(() => {
                window.localStorage.removeItem('vuex')
                this.$router.push({
                    name: 'LoginView',
                })
            })
        },
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapGetters('auth', ['isAuthenticated']),
    },
}
</script>

<style lang="scss" scoped>
    .button-text {
        text-transform: none;
        display: inline-block;
        margin-top: -1px;
    }
    .user-text {
        color: white;
        display: inline-block;
        padding-top: .25rem;
        margin-left: 2rem;
        margin-right: 0.5rem;
    }
</style>

import CONFIG from '@root/config'

export const SET_PRODUCT_CONTENT_CATEGORIES = 'SET_PRODUCT_CONTENT_CATEGORIES'
export const SET_PRODUCT_CONTENT_TAGS = 'SET_PRODUCT_CONTENT_TAGS'
export const SET_MARKETPLACE_PRODUCT_CONTENT = 'SET_MARKETPLACE_PRODUCT_CONTENT'
export const SAVE_CATALOG_PRODUCT_CONTENT_SECTION = 'SAVE_CATALOG_PRODUCT_CONTENT_SECTION'
export const SET_SUBMISSION_SPECIFICATIONS = 'SET_SUBMISSION_SPECIFICATIONS'
export const SAVE_PRODUCT_CONTENT_CATEGORY = 'SAVE_PRODUCT_CONTENT_CATEGORY'
export const SET_FAQS = 'SET_FAQS'

export default {
    namespaced: true,

    state: {
        categories: null,
        tags: null,
        catalogProducts: {},
        catalogProductsSaving: {},
        submissionSpecifications: null,
        faqs: null,
    },

    mutations: {
        [ SET_PRODUCT_CONTENT_CATEGORIES ] (state, categories) {
            if (categories === false) {
                this._vm.$set(state, 'categories', false)
                return
            }

            this._vm.$set(state, 'categories', categories)
        },
        [ SET_PRODUCT_CONTENT_TAGS ] (state, tags) {
            if (tags === false) {
                this._vm.$set(state, 'tags', false)
                return
            }

            this._vm.$set(state, 'tags', tags)
        },

        [ SET_MARKETPLACE_PRODUCT_CONTENT ] (state, marketplaceProductContent) {
            this._vm.$set(state.marketplaceProducts, marketplaceProductContent.marketplaceProductId, {
                ...marketplaceProductContent,
            })
        },

        [ SET_SUBMISSION_SPECIFICATIONS ] (state, submissionSpecifications) {
            this._vm.$set(state, 'submissionSpecifications', submissionSpecifications)
        },

        [ SET_FAQS ] (state, faqs) {
            this._vm.$set(state, 'faqs', faqs)
        },
    },

    actions: {
        getProductContentCategories ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_PRODUCT_CONTENT_CATEGORIES, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                this._vm.$api.post(CONFIG.API.ROUTES.PRODUCT_CONTENT.CATEGORIES.LIST).then(({ data }) => {
                    commit(SET_PRODUCT_CONTENT_CATEGORIES, data.data)
                }).catch(() => {
                    commit(SET_PRODUCT_CONTENT_CATEGORIES, false)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_PRODUCT_CONTENT_CATEGORIES, {
                        root: true,
                    })
                })
            })
        },

        getProductContentTags ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_PRODUCT_CONTENT_TAGS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                this._vm.$api.post(CONFIG.API.ROUTES.PRODUCT_CONTENT.TAGS.LIST).then(({ data }) => {
                    commit(SET_PRODUCT_CONTENT_TAGS, data.data)
                }).catch(() => {
                    commit(SET_PRODUCT_CONTENT_TAGS, false)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_PRODUCT_CONTENT_TAGS, {
                        root: true,
                    })
                })
            })
        },
        getSubmissionSpecifications ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_SUBMISSION_SPECIFICATIONS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                this._vm.$api.post(CONFIG.API.ROUTES.PRODUCT_CONTENT.SUBMISSION_SPECIFICATIONS.LIST).then(({ data }) => {
                    commit(SET_SUBMISSION_SPECIFICATIONS, data.data.items)
                }).catch(() => {
                    commit(SET_SUBMISSION_SPECIFICATIONS, false)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_SUBMISSION_SPECIFICATIONS, {
                        root: true,
                    })
                })
            })
        },

        getFaqs ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_FAQS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                this._vm.$api.post(CONFIG.API.ROUTES.PRODUCT_CONTENT.FAQS.LIST).then(({ data }) => {
                    commit(SET_FAQS, data.data.items)
                }).catch(() => {
                    commit(SET_FAQS, false)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_FAQS, {
                        root: true,
                    })
                })
            })
        },

        saveCatalogProductContentSection ({ dispatch, commit, state }, {
            catalogProductId,
            section,
            payload,
            productContentId,
        }) {
            const jobName = SAVE_CATALOG_PRODUCT_CONTENT_SECTION + section
            dispatch('loading/addJob', jobName, {
                root: true,
            })
            this._vm.$set(state.catalogProductsSaving, catalogProductId, true)
            payload.productContentId = productContentId

            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.CATALOG.UPDATE.replace('{catalogProductId}', catalogProductId).replace('{section}', section)
                this._vm.$api.post(url, payload).then(({ data }) => {
                    resolve(data.data)
                }).catch(error => {
                    this._vm.$root.$emit('notification:global', {
                        message: `Saving product content failed. Please try again.`,
                        type: 'error',
                        errors: error,
                    })
                    reject(error)
                }).finally(() => {
                    this._vm.$set(state.catalogProductsSaving, catalogProductId, false)
                    dispatch('loading/finishJob', jobName, {
                        root: true,
                    })
                })
            })
        },
    },
    getters: {
        getCategoryById (state) {
            return id => {
                if (!state.categories) {
                    return null
                }
                return state.categories.find(item => {
                    return item.productCategoryId === id
                })
            }
        },
    },
}

<template>
    <div data-e2e="shopSelector" class="c-shop-switcher">
        <pretty-select
            v-if="application.applicationId"
            placeholder="Shop"
            label="applicationName"
            :options="shopsWithAll"
            v-model="application.applicationId"
            :reduce="application => application.applicationId"
            class="flex-grow-1"
            :clearable="false"
            @input="changeApplicationConfig($event)"
        />
    </div>
</template>

<script>
import CONFIG from '@root/config'
import { mapActions, mapState } from 'vuex'
import PrettySelect from '../utils/PrettySelect'
export default {
    name: 'ShopSwitcher',
    components: { PrettySelect },
    data () {
        return {
            application: {
                applicationId: null,
            },
            allApplications: {
                applicationId: '0',
                applicationName: 'allShops',
            },
            globalshop: {
                applicationId: '2ed08b86-bc1f-52e1-9643-1d81e9b20498',
                organisationId: 'eed4a84b-b073-4146-a9fa-631a0d0d6eda',
                applicationName: 'api.globalshop.helloprint.com',
            },
        }
    },
    watch: {
        selectedShop: {
            handler (value) {
                if (!value) {
                    return
                }
                if (this.application.applicationId !== value.applicationId) {
                    this.$set(this, 'application', value)
                }
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        ...mapState('shop', ['shops']),
        shopsWithAll () {
            if (!this.shops) {
                return []
            }

            return [
                {
                    applicationName: 'All Shops',
                    applicationId: '0',
                },
                ...this.shops,
            ]
        },
    },
    methods: {
        ...mapActions('shop', ['setShop', 'fetchShops']),
        checkConfig () {
            const cookie = this.$cookies.get(CONFIG.COOKIES.SHOP.KEY)

            if (cookie === null || cookie === 'undefined') {
                this.setDefaultShop()
                return
            }

            this.application = cookie
            if (!Object.keys(this.application).length) {
                this.setDefaultShop()
            }
        },
        setDefaultShop () {
            if (!this.shops.length) {
                return
            }
            this.application = this.globalshop
            this.setShop(this.globalshop)
        },
        changeApplicationConfig (id) {
            const cookie = this.$cookies.get(CONFIG.COOKIES.SHOP.KEY)
            if (id === cookie.applicationId) {
                return
            }
            this.shops.forEach((item) => {
                if (item.applicationId === id) {
                    this.setShop(item)
                }
            })

            if (id === '0') {
                this.setShop(this.allApplications)
            }

            if (CONFIG.SHOP_UNIQUE_VIEWS.includes(this.$route.name)) {
                this.$bus.$emit('shopWasChangedOnUniqueView')
            } else {
                this.$bus.$emit('shopWasChanged')
            }
        },
    },
    created () {
        if (this.shops.length) {
            this.checkConfig()
            return
        }

        this.fetchShops().then(() => {
            this.checkConfig()
        })
    },
}
</script>

<style lang="scss" scoped>
    .c-shop-switcher {
        background-color: rgba(216,216,216,.22);
        max-width: 300px;
        max-height: 40px;
        border-radius: 3px;
        padding: 14px 10px 0 !important;
        min-height: 40px !important;
        position: relative;
        width: 100%;

        /deep/ .v-select {
            margin: 0;
            font-size: 15px !important;
            height: unset;
        }

        /deep/ .vs__dropdown-toggle {
            padding: 0;
            border: none;
        }

        /deep/ .vs__selected {
            color: white;
            border: none;
            flex-wrap: nowrap;
            margin-top: 0;
            white-space: nowrap;
            max-width: 90%;
            overflow: hidden;
        }

        /deep/ .vs__actions {
            position: absolute;
            right: 0;

            svg {
                fill: white;
            }
        }

        /deep/ .vs__search, .vs__search:focus {
            color: white;
        }

        /deep/ label {
            color: white !important;
            font-size: 11px !important;
            top: -2px;
            left: 9px;
            position: absolute;
        }

        /deep/ .vs__dropdown-menu {
            margin-top: 3px;
        }

        /deep/ .vs__dropdown-option--highlight  {
            background-color: rgba(0,0,0,.12);
        }

        /deep/ .vs__dropdown-option {
            font-size: 13px;
            padding: 11px  20px;
        }
    }
</style>

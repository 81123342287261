import Vue from 'vue'
import App from './App'
import router from './router/router'
import CONFIG from '@root/config'
import VueCookies from 'vue-cookies'
import store from './store/index'
import VueSetup from './plugins/setup'
import UUID from 'vue-uuid'
import Vuelidate from 'vuelidate'
import VueCurrencyFilter from 'vue-currency-filter'
import { LicenseManager } from 'ag-grid-enterprise'
import vTitle from './components/utils/VueTitle'
import TitleBox from './components/elements/TitleBox'
import Currency from './components/elements/Currency'
import MercurComponents from '@platform-frontend-components/mercur-components'
import Vuex from 'vuex'

LicenseManager.setLicenseKey(CONFIG.VUE_APP_AG_GRID_LICENSE_KEY)

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(UUID)
Vue.use(Vuelidate)
Vue.use(Vuex)
const vuexStore = new Vuex.Store(store)
Vue.use(VueSetup, {
    store: vuexStore,
    router,
})
Vue.use(VueCurrencyFilter, {})

Object.keys(MercurComponents).forEach(name => {
    Vue.component(name, MercurComponents[name])
})

Vue.component('v-title', vTitle)
Vue.component('title-box', TitleBox)
Vue.component('currency', Currency)

new Vue({
    router,
    store: vuexStore,
    render: h => h(App),
}).$mount('#app')

import CONFIG from '@root/config'

export default {
    namespaced: true,
    actions: {
        initSession () {
            if (!this._vm.$cookies.get(CONFIG.COOKIES.SESSION.KEY)) {
                this._vm.$cookies.set(CONFIG.COOKIES.SESSION.KEY, this._vm.$uuid.v4())
            }

            this._vm.$api.setSession(this._vm.$cookies.get(CONFIG.COOKIES.SESSION.KEY))
        },
    },
}

import jwt from 'jwt-simple'

import CONFIG from '@root/config'
import { SET_USER, SET_LAST_VISITED_PATH, SET_USER_ADDITIONAL_DATA } from '@/store/mutation-types'

export default {
    namespaced: true,
    state: {
        user: null,
        userAdditionalData: null,
        path: null,
        isLoading: false,
    },

    mutations: {
        [ SET_USER ] (state, user) {
            state.user = user
        },

        [ SET_LAST_VISITED_PATH ] (state, path) {
            state.path = path
        },

        [ SET_USER_ADDITIONAL_DATA ] (state, userAdditionalData) {
            state.userAdditionalData = userAdditionalData
        },
    },

    actions: {
        loginGuest (context, userData) {
            context.commit(SET_USER, {
                ...userData,
                guest: true,
            })
        },

        updateAuth (context, token) {
            this._vm.$api.setToken(token)
            const decoded = jwt.decode(token, '', true)
            context.commit(SET_USER, {
                guest: false,
                ...decoded,
                token,
            })
            this._vm.$cookies.set(CONFIG.COOKIES.TOKEN.KEY, token, new Date(decoded.exp * 1000), '/')
        },

        login (context, token) {
            context.dispatch('updateAuth', token)

            return context.state.path
        },

        logout (context) {
            context.commit(SET_USER, null)
            this._vm.$api.setToken('')
            this._vm.$cookies.remove(CONFIG.COOKIES.TOKEN.KEY, '/')
            this._vm.$cookies.remove(CONFIG.COOKIES.SESSION.KEY, '/')
            this._vm.$cookies.remove(CONFIG.COOKIES.LOCALE.KEY, '/')
            this._vm.$cookies.remove(CONFIG.COOKIES.SHOP.KEY, '/')
            context.dispatch('shop/clearState', {}, { root: true })
        },

        check (context) {
            const token = this._vm.$cookies.get(CONFIG.COOKIES.TOKEN.KEY)

            if (token) {
                context.dispatch('login', token)
            }
        },

        getAccountAdditionalSettings ({ state, commit }) {
            const url = CONFIG.API.ROUTES.AGENTS.GET.replace('{agentId}', state.user.accountId)
            return this._vm.$api.get(url).then(({ data }) => {
                commit(SET_USER_ADDITIONAL_DATA, data.data)
            })
        },

        patchUser ({ commit, state }, data) {
            commit(SET_USER, Object.assign(state.user, data))
        },

        guard (context, { to, from, next }) {
            if (!context.getters.isGuest) {
                return next()
            }
            context.commit(SET_LAST_VISITED_PATH, to.path)
            context.dispatch('logout')

            return next(CONFIG.NAVIGATION.LOGIN_PATH)
        },

        async recoverPassword ({ dispatch }, params) {
            const url = CONFIG.API.ROUTES.ACCOUNT.PASSWORD.RECOVER_REQUEST

            dispatch('loading/addJob', url, {
                root: true,
            })

            let requestData = {}
            if (params.accountId) {
                requestData = { accountId: params.accountId, username: params.username }
            } else {
                requestData = { username: params.username }
            }
            return new Promise((resolve, reject) => {
                this._vm.$api.post(url, requestData).then(({ data }) => {
                    resolve(data)
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', url, {
                        root: true,
                    })
                })
            })
        },

        async resetPassword ({ dispatch }, params) {
            const url = CONFIG.API.ROUTES.CUSTOMERS.RESET_PASSWORD

            dispatch('loading/addJob', url, {
                root: true,
            })

            let requestData = {}
            if (params.accountId) {
                requestData = { accountId: params.accountId, username: params.username }
            } else {
                requestData = { username: params.username }
            }
            return new Promise((resolve, reject) => {
                this._vm.$api.post(url, requestData).then(({ data }) => {
                    resolve(data)
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', url, {
                        root: true,
                    })
                })
            })
        },
    },

    getters: {
        user (state) {
            return state.user || {}
        },

        isGuest (state) {
            return state.user === null || state.user.guest === true
        },

        isAuthenticated (state) {
            return state.user !== null && state.user.guest !== true && state.allowedActions !== null
        },
    },
}

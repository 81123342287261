<script>

const emitError = (emitter, err, fallbackMessage) => {
    console.log(err)

    const { exceptions, defaultMessage } = (err && err.data) || {}

    const { error, reason_phrase: reasonPhrase } = (exceptions && exceptions[0]) || {}

    const errorMessage = typeof error === 'string' ? error : reasonPhrase

    emitter.$emit('notification:global', {
        message: errorMessage || defaultMessage || fallbackMessage,
        type: 'error',
    })
}

export default {
    name: 'ApiMixin',
    methods: {
        post (url, payload, notification = null, errorMessage = 'Something went wrong') {
            return new Promise((resolve, reject) => {
                this.addJob(url)
                if (this.isWithTemporaryShop) {
                    this.$api.setShop(JSON.stringify(this.temporaryShop))
                }
                this.$api.post(url, payload).then(({ data }) => {
                    resolve(data)
                    if (notification !== null) {
                        this.$root.$emit('notification:global', {
                            message: notification,
                            type: 'success',
                        })
                    }
                }).catch(err => {
                    emitError(this.$root, err, errorMessage)
                    reject(err)
                }).finally(() => {
                    this.finishJob(url)
                })
                if (this.isWithTemporaryShop) {
                    this.$api.setShop(JSON.stringify(this.selectedShop))
                }
            })
        },

        get (url, notification = null, errorMessage = 'Something went wrong') {
            return new Promise((resolve, reject) => {
                this.addJob(url)
                if (this.isWithTemporaryShop) {
                    this.$api.setShop(JSON.stringify(this.temporaryShop))
                }

                this.$api.get(url).then(({ data }) => {
                    resolve(data)
                    if (notification !== null) {
                        this.$root.$emit('notification:global', {
                            message: notification,
                        })
                    }
                }).catch(err => {
                    emitError(this.$root, err, errorMessage)
                    reject(err)
                }).finally(() => {
                    this.finishJob(url)
                })

                if (this.isWithTemporaryShop) {
                    this.$api.setShop(JSON.stringify(this.selectedShop))
                }
            })
        },
        pollingPost ({
            url,
            responseUrl = null,
            payload,
            responsePayload = {},
            notification = null,
            errorMessage = 'Something went wrong',
        }) {
            if (responseUrl === null) {
                responseUrl = url
            }
            return new Promise(async (resolve, reject) => {
                this.addJob(url)
                const numberOfTries = 1200

                const requestTokenResponse = await this.post(url, payload)

                if (!requestTokenResponse || !requestTokenResponse.data || !requestTokenResponse.data.token) {
                    reject(new Error({
                        message: 'NO_REQUEST_TOKEN_FOUND',
                    }))
                    return
                }

                const requestToken = requestTokenResponse.data.token
                responseUrl = responseUrl.replace('{token}', requestToken)

                for (let triesLeft = numberOfTries; triesLeft >= 0; triesLeft--) {
                    if (this.isWithTemporaryShop) {
                        this.$api.setShop(JSON.stringify(this.temporaryShop))
                    }
                    let possibleResponse

                    possibleResponse = await this.post(responseUrl, responsePayload, notification, '').catch(() => {})
                    if (!possibleResponse && triesLeft === 0) {
                        reject(new Error({
                            message: 'MAX_RETRIES_EXCEEDED',
                        }))
                        return
                    }
                    if (possibleResponse) {
                        resolve(possibleResponse)
                        if (notification !== null) {
                            this.$root.$emit('notification:global', {
                                message: notification,
                                type: 'success',
                            })
                        }

                        this.finishJob(url)

                        return
                    }
                    if (this.isWithTemporaryShop) {
                        this.$api.setShop(JSON.stringify(this.selectedShop))
                    }
                    await new Promise(resolve => setTimeout(resolve, 1000))
                }
            })
        },
    },
}
</script>

import CONFIG from '@root/config'
import { SET_CUSTOMER, SET_CUSTOMER_BILLING_ADDRESS, SET_CUSTOMER_SHIPPING_ADDRESS, UPDATE_CUSTOMER, SET_ACTIVE_DTP_AGENT, SET_ADRESSES } from '@/store/mutation-types'

export default {
    namespaced: true,
    strict: true,
    state: {
        customer: {},
        activeDtpAgent: {},
        addresses: [],
    },

    mutations: {
        [ SET_CUSTOMER ] (state, data) {
            this._vm.$set(state, 'customer', data)
        },
        [ SET_ACTIVE_DTP_AGENT ] (state, data) {
            this._vm.$set(state, 'activeDtpAgent', data)
        },
        [ UPDATE_CUSTOMER ] (state, data) {
            state.customer = { ...state.customer, ...data }
        },
        [ SET_CUSTOMER_BILLING_ADDRESS ] (state, data) {
            this._vm.$set(state.customer, 'defaultBillingAddress', data)
        },
        [ SET_CUSTOMER_SHIPPING_ADDRESS ] (state, data) {
            this._vm.$set(state.customer, 'defaultShippingAddress', data)
        },
        [ SET_ADRESSES ] (state, data) {
            this._vm.$set(state, 'addresses', data)
        },
    },

    actions: {
        async fetchCustomer ({ dispatch, commit, state }, params) {
            if (state.customer.accountId !== params.accountId) {
                commit(SET_CUSTOMER, {})
                commit(SET_ADRESSES, [])
            }
            dispatch('loading/addJob', SET_CUSTOMER, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.CUSTOMERS.GET.OVERVIEW.replace('{customerId}', params.accountId)
            return new Promise((resolve, reject) => {
                this._vm.$api.get(route).then(({ data }) => {
                    if (Array.isArray(data.data.accountSettings)) {
                        data.data.accountSettings = {}
                    }
                    if (Array.isArray(data.data.accountConfiguration)) {
                        data.data.accountConfiguration = {}
                    }

                    commit(SET_CUSTOMER, data.data)
                    dispatch('fetchAddresses')
                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_CUSTOMER, {
                        root: true,
                    })
                })
            })
        },

        async setDefaultBillingAddressForCustomer ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', params.url, {
                root: true,
            })
            await this._vm.$api.post(params.url, {}).then(({ data }) => {
                commit(SET_CUSTOMER_BILLING_ADDRESS, data.data.defaultBillingAddress)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', params.url, {
                    root: true,
                })
            })
        },

        async setDefaultShippingAddressForCustomer ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', params.url, {
                root: true,
            })
            await this._vm.$api.post(params.url, {}).then(({ data }) => {
                commit(SET_CUSTOMER_SHIPPING_ADDRESS, data.data.defaultShippingAddress)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', params.url, {
                    root: true,
                })
            })
        },

        updateCustomer ({ commit }, params) {
            commit(UPDATE_CUSTOMER, params.data)
        },

        async fetchAddresses ({ dispatch, commit, state }) {
            if (!Object.keys(state.customer).length) {
                return
            }

            const url = CONFIG.API.ROUTES.CUSTOMERS.GET.ADDRESSES
                .replace('{customerId}', state.customer.accountId)
                .replace('{organisationId}', state.customer.organisation.organisationId)

            dispatch('loading/addJob', SET_ADRESSES, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                this._vm.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    if (data.data.length) {
                        data.data.forEach((address) => {
                            address.label = `${address.address} ${address.addressAdditional}, ${address.zipCode} ${address.city}, ${address.country}`
                        })
                    }
                    commit(SET_ADRESSES, data.data)
                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_ADRESSES, {
                        root: true,
                    })
                })
            })
        },

        async setActiveDtpAgent ({ commit, state, dispatch }, agent) {
            if (state.activeDtpAgent.accountId !== agent.accountId) {
                commit(SET_ACTIVE_DTP_AGENT, {})
            }
            commit(SET_ACTIVE_DTP_AGENT, agent)
        },
    },
}

<template>
    <span class="o-currency">
        {{ params.value ? params.value : value | currency({
            ...values
        }) }}
    </span>
</template>

<script>
import Vue from 'vue'
import { CURRENCIES_LIST } from '@root/config/currencies'

export default Vue.extend({
    name: 'Currency',

    props: {
        value: {},
        type: {
            default: 'EUR',
        },
    },

    data () {
        return {
            params: {},
        }
    },

    computed: {
        currencyCode () {
            return this.params.type ? this.params.type : this.type
        },
        values () {
            if (!this.currencyCode || !CURRENCIES_LIST[this.currencyCode]) {
                return
            }

            return {
                symbol: CURRENCIES_LIST[this.currencyCode].SYMBOL,
                thousandsSeparator: CURRENCIES_LIST[this.currencyCode].THOUSANDSSEPARATOR,
                fractionCount: CURRENCIES_LIST[this.currencyCode].FRACTIONCOUNT,
                fractionSeparator: CURRENCIES_LIST[this.currencyCode].FRACTIONSEPARATOR,
                symbolPosition: CURRENCIES_LIST[this.currencyCode].SYMBOLPOSITION,
                symbolSpacing: CURRENCIES_LIST[this.currencyCode].SYMBOLSPACING,
            }
        },
    },
})
</script>

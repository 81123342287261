import countries from 'country-region-data'

export const transformObjectEmptyArraysToEmptyObjects = (data) => {
    for (let key in data) {
        if (data[key]) {
            if (Array.isArray(data[key]) && !data[key].length) {
                data[key] = {}
            }

            if (typeof data[key] === 'object' && Object.keys(data[key]).length) {
                transformObjectEmptyArraysToEmptyObjects(data[key])
            }
        }
    }
}

export const filterEmptyTurnarounds = (data) => {
    for (let key in data) {
        for (let key2 in data[key]) {
            if (parseInt(data[key][key2]) > 5 || parseInt(data[key][key2]) < 1) {
                delete data[key][key2]
            }
        }

        if (!Object.keys(data[key]).length) {
            delete data[key]
        }
    }

    return data
}

export const totalsCalculator = (payload, freeShipping = false, discounts = [], withoutVat = false) => {
    let value = 0
    payload.forEach((item) => {
        const productPrice = parseFloat(item.productPrice)
        const shippingPrice = parseFloat(item.shippingPrice)

        if (item.productVatRate && !withoutVat) {
            let vatValue = productPrice * item.productVatRate
            value += productPrice + vatValue
        } else {
            value += productPrice
        }

        if (freeShipping) {
            return
        }

        if (item.shippingVatRate && !withoutVat) {
            value += shippingPrice + (shippingPrice * item.shippingVatRate)
        } else {
            value += shippingPrice
        }
    })

    if (discounts.length > 0) {
        discounts.forEach((discount) => {
            value = value - parseFloat(discount.discountEffects.amount)
        })
    }

    return value.toFixed(2)
}

export function generatePassword (passwordLength = 10) {
    let password = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < passwordLength; i++) {
        password += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return password
}

export const copyToClipboard = (value) => {
    const el = document.createElement('textarea')
    el.value = value
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
}

export const transformProductAttributes = (attributes) => {
    let arr = []
    Object.entries(attributes).forEach((entry) => {
        arr.push({
            attributeName: entry[0],
            attributeOption: entry[1],
        })
    })
    return arr
}

export const transformProductAttributesToObject = (attributes) => {
    let obj = {}
    attributes.forEach((attribute) => {
        obj[attribute.attributeName] = attribute.attributeOption
    })
    return obj
}

export const getCountryShortCode = (country) => {
    return countries.find(e => e.countryName === country).countryShortCode
}

export const cartesianProduct = (arr) => {
    return arr.reduce(function (a, b) {
        return a.map(function (x) {
            return b.map(function (y) {
                return x.concat([y])
            })
        }).reduce(function (a, b) { return a.concat(b) }, [])
    }, [[]])
}

import CONFIG from '@root/config'
import {
    SET_PRODUCT_FEEDS,
    SET_CONFIGURATIONS,
    SET_FIELDS,
    SET_CURRENT_PRODUCT_FEED,
    SET_CURRENT_CONFIGURATION,
    SET_CURRENT_FIELD,
    SET_PRODUCT_FEED_DETAILS,
} from '@/store/mutation-types'

export default {
    namespaced: true,
    strict: true,
    state: {
        productFeeds: [],
        configurations: [],
        fields: [],
        currentProductFeed: {},
        currentConfiguration: {},
        currentField: {},
        productFeedDetails: {},
    },

    mutations: {
        [ SET_PRODUCT_FEEDS ] (state, data) {
            this._vm.$set(state, 'productFeeds', data)
        },
        [ SET_CONFIGURATIONS ] (state, data) {
            this._vm.$set(state, 'configurations', data)
        },
        [ SET_FIELDS ] (state, data) {
            this._vm.$set(state, 'fields', data)
        },
        [ SET_CURRENT_PRODUCT_FEED ] (state, data) {
            this._vm.$set(state, 'currentProductFeed', data)
        },
        [ SET_CURRENT_CONFIGURATION ] (state, data) {
            this._vm.$set(state, 'currentConfiguration', data)
        },
        [ SET_CURRENT_FIELD ] (state, data) {
            this._vm.$set(state, 'currentField', data)
        },
        [ SET_PRODUCT_FEED_DETAILS ] (state, data) {
            this._vm.$set(state, 'productFeedDetails', data)
        },
    },

    actions: {
        async fetchProductFeeds ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_PRODUCT_FEEDS, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.PRODUCT_FEEDS.OVERVIEW

            await this._vm.$api.get(route).then(({ data }) => {
                commit(SET_PRODUCT_FEEDS, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_PRODUCT_FEEDS, {
                    root: true,
                })
            })
        },

        async fetchProductFeedDetails ({ dispatch, commit }, productFeedId) {
            dispatch('loading/addJob', SET_PRODUCT_FEED_DETAILS, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.PRODUCT_FEEDS.DETAILS.replace('{productFeedId}', productFeedId)

            await this._vm.$api.post(route, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                commit(SET_PRODUCT_FEED_DETAILS, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_PRODUCT_FEED_DETAILS, {
                    root: true,
                })
            })
        },

        async fetchConfigurations ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_CONFIGURATIONS, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.PRODUCT_FEEDS.CONFIGURATIONS.OVERVIEW

            await this._vm.$api.post(route, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                commit(SET_CONFIGURATIONS, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_CONFIGURATIONS, {
                    root: true,
                })
            })
        },

        async fetchFields ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_FIELDS, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.PRODUCT_FEEDS.FIELDS.OVERVIEW

            await this._vm.$api.post(route, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                commit(SET_FIELDS, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_FIELDS, {
                    root: true,
                })
            })
        },

        async getCurrentProductFeed ({ dispatch, commit }, productFeedId) {
            dispatch('loading/addJob', SET_CURRENT_PRODUCT_FEED, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.PRODUCT_FEEDS.GET.replace('{productFeedId}', productFeedId)

            await this._vm.$api.get(route).then(({ data }) => {
                commit(SET_CURRENT_PRODUCT_FEED, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_CURRENT_PRODUCT_FEED, {
                    root: true,
                })
            })
        },

        async getCurrentConfiguration ({ dispatch, commit }, configurationId) {
            dispatch('loading/addJob', SET_CURRENT_CONFIGURATION, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.PRODUCT_FEEDS.CONFIGURATIONS.GET.replace('{configurationId}', configurationId)

            await this._vm.$api.get(route).then(({ data }) => {
                commit(SET_CURRENT_CONFIGURATION, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_CURRENT_CONFIGURATION, {
                    root: true,
                })
            })
        },

        async getCurrentField ({ dispatch, commit }, fieldId) {
            dispatch('loading/addJob', SET_CURRENT_FIELD, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.PRODUCT_FEEDS.FIELDS.GET.replace('{fieldId}', fieldId)

            await this._vm.$api.get(route).then(({ data }) => {
                commit(SET_CURRENT_FIELD, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_CURRENT_FIELD, {
                    root: true,
                })
            })
        },
    },

    getters: {
        getFieldsByIds (state) {
            return ids => state.fields.filter((field) => { return ids.includes(field.fieldId) })
        },

        getFieldsByNames (state) {
            return names => state.fields.filter((field) => { return names.includes(field.fieldName) })
        },

        getConfigurationById (state) {
            return id => state.configurations.find((configuration) => { return configuration.configurationId === id })
        },

        systemFields (state) {
            return Object.values(state.fields).filter((field) => { return field['fieldType'] === 'SYSTEM' })
        },
        customFields (state) {
            return Object.values(state.fields).filter((field) => { return field['fieldType'] === 'CUSTOM' })
        },
    },
}

<template>
    <div class="o-breadcrumbs">
        <div class="container-fluid">
            <router-link :to="{name: 'HomeView'}" class="o-breadcrumbs__item">
                <span class="o-breadcrumbs__item-text o-breadcrumbs__item-text--home">Home</span>
            </router-link>
            <span v-for="(breadCrumb, key) in breadCrumbs" :key="key">
            <span class="o-breadcrumbs__icon"><i class="fas fa-chevron-right"></i></span>
            <router-link :to="breadCrumb.viewName" :is="key < breadCrumbs.length - 1 ? 'router-link' : 'span'" class="o-breadcrumbs__item">
                <span class="o-breadcrumbs__item-text">{{breadCrumb.prettyName | capitalize}} {{ breadCrumb.id }}</span>
            </router-link>
        </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Breadcrumbs',
    props: ['routeview'],

    data () {
        return {
            breadCrumbs: [],
        }
    },
    computed: {
        ...mapGetters('loading', [ 'getPendingJobs' ]),
    },

    methods: {
        updateBreadcrumbs () {
            this.$nextTick(() => {
                let breadCrumbs = []
                let breadcrumbRef = this.routeview
                this.$set(this, 'breadCrumbs', [])

                while (breadcrumbRef) {
                    let breadcrumbElement = breadcrumbRef.breadcrumbElement || breadcrumbRef.$attrs.breadcrumbElement
                    if (breadcrumbElement) {
                        breadCrumbs.push({
                            prettyName: breadcrumbElement.name,
                            id: breadcrumbRef.breadCrumbId,
                            viewName: {
                                name: breadcrumbRef.$options.name,
                            },
                        })
                    }

                    if (breadcrumbRef.$refs && breadcrumbRef.$refs.routeview) {
                        breadcrumbRef = breadcrumbRef.$refs.routeview
                    } else {
                        breadcrumbRef = false
                    }
                }
                this.$set(this, 'breadCrumbs', breadCrumbs)
            })
        },
    },

    created () {
        this.$watch(() => {
            return this.$route.fullPath + JSON.stringify(this.getPendingJobs)
        }, this.updateBreadcrumbs)
    },
    watch: {
        routeview () {
            this.updateBreadcrumbs()
        },
    },
}
</script>

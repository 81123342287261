export const CURRENCIES_LIST = {
	EUR: {
		TYPE: 'EUR',
		SYMBOL: '€',
		THOUSANDSSEPARATOR: '.',
		FRACTIONCOUNT: '2',
		FRACTIONSEPARATOR: ',',
		SYMBOLPOSITION: 'front',
		SYMBOLSPACING: 'true',
	},
	SEK: {
		TYPE: 'SEK',
		SYMBOL: 'kr',
		THOUSANDSSEPARATOR: '.',
		FRACTIONCOUNT: '2',
		FRACTIONSEPARATOR: ',',
		SYMBOLPOSITION: 'back',
		SYMBOLSPACING: 'true',
	},
	DKK: {
		TYPE: 'DKK',
		SYMBOL: 'kr.',
		THOUSANDSSEPARATOR: '.',
		FRACTIONCOUNT: '2',
		FRACTIONSEPARATOR: ',',
		SYMBOLPOSITION: 'back',
		SYMBOLSPACING: 'true',
	},
    PLN: {
        TYPE: 'PLN',
        SYMBOL: 'zł',
        THOUSANDSSEPARATOR: '.',
        FRACTIONCOUNT: '0',
        FRACTIONSEPARATOR: ',',
        SYMBOLPOSITION: 'back',
        SYMBOLSPACING: 'true',
    },
    BGN: {
        TYPE: 'BGN',
        SYMBOL: 'лв',
        THOUSANDSSEPARATOR: '.',
        FRACTIONCOUNT: '0',
        FRACTIONSEPARATOR: ',',
        SYMBOLPOSITION: 'front',
        SYMBOLSPACING: 'true',
    },
    CZK: {
        TYPE: 'CZK',
        SYMBOL: 'Kč',
        THOUSANDSSEPARATOR: '.',
        FRACTIONCOUNT: '0',
        FRACTIONSEPARATOR: ',',
        SYMBOLPOSITION: 'front',
        SYMBOLSPACING: 'true',
    },
    HRK: {
        TYPE: 'HRK',
        SYMBOL: 'kn',
        THOUSANDSSEPARATOR: '.',
        FRACTIONCOUNT: '0',
        FRACTIONSEPARATOR: ',',
        SYMBOLPOSITION: 'front',
        SYMBOLSPACING: 'true',
    },
    HUF: {
        TYPE: 'HUF',
        SYMBOL: 'ft',
        THOUSANDSSEPARATOR: '.',
        FRACTIONCOUNT: '0',
        FRACTIONSEPARATOR: ',',
        SYMBOLPOSITION: 'front',
        SYMBOLSPACING: 'true',
    },
    RON: {
        TYPE: 'RON',
        SYMBOL: 'lei',
        THOUSANDSSEPARATOR: '.',
        FRACTIONCOUNT: '0',
        FRACTIONSEPARATOR: ',',
        SYMBOLPOSITION: 'back',
        SYMBOLSPACING: 'true',
    },
}

export default {
	CURRENCIES_LIST,
}

import CONFIG from '@root/config'
import { SET_SHOP_CONFIG, ADD_SHOP_CONFIG_ITEM, CREATE_SHOP_CONFIG, ADD_NEW_CONFIG, SET_CURRENT_SHOP, CLEAR_SHOP_CONFIG, REMOVE_CONFIG_KEY, REMOVE_SHOP_CONFIG } from '@/store/mutation-types'
import { transformObjectEmptyArraysToEmptyObjects } from '@/utils/helpers'

export default {
    namespaced: true,
    state: {
        shopConfigs: {},
        currentShop: null,
    },
    mutations: {
        [ SET_SHOP_CONFIG ] (state, payload) {
            this._vm.$set(state.shopConfigs, payload.key, payload.data)
        },
        [ CLEAR_SHOP_CONFIG ] (state) {
            state.shopConfigs = {}
        },
        [ ADD_SHOP_CONFIG_ITEM ] (state, payload) {
            let value = payload.value
            if (value === '') {
                value = {}
            }
            this._vm.$set(payload.item, payload.key, value)
        },
        [ SET_CURRENT_SHOP ] (state, id) {
            state.currentShop = id
        },
        [ ADD_NEW_CONFIG ] (state, payload) {
            state.shopConfigs[payload.key].push(payload.data)
        },
        [ REMOVE_SHOP_CONFIG ] (state, payload) {
            const category = state.shopConfigs[payload.category]
            const index = category.findIndex(config => config.configurationId === payload.id)
            this._vm.$delete(category, index)
        },
        [ REMOVE_CONFIG_KEY ] (state, payload) {
            let target = payload.item
            for (let key = 0; key < payload.path.length - 1; key++) {
                target = target[payload.path[key]]
            }
            const lastKey = payload.path[payload.path.length - 1]
            this._vm.$delete(target, lastKey)
        },
    },
    actions: {
        async fetchShopConfig ({ commit, dispatch, state, rootState }, params) {
            dispatch('loading/addJob', SET_SHOP_CONFIG, {
                root: true,
            })
            if (state.currentShop && state.currentShop !== rootState.shop.selectedShop.applicationId) {
                commit(CLEAR_SHOP_CONFIG)
            }
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.SHOP_CONFIG.OVERVIEW.replace('{category}', params.category)
                this._vm.$api.get(url).then(({ data }) => {
                    data.data.forEach((config) => {
                        transformObjectEmptyArraysToEmptyObjects(config.configurationData)
                    })

                    const payload = {
                        key: params.category,
                        data: data.data,
                    }
                    commit(SET_SHOP_CONFIG, payload)
                    commit(SET_CURRENT_SHOP, data.data[0].applicationId)
                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_SHOP_CONFIG, {
                        root: true,
                    })
                })
            })
        },
        async createShopConfig ({ commit, dispatch, state }, params) {
            dispatch('loading/addJob', CREATE_SHOP_CONFIG, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.SHOP_CONFIG.CREATE
                this._vm.$api.post(url, params).then(({ data }) => {
                    const category = data.data.configurationCategory
                    if (!state.shopConfigs[category]) {
                        const emptyData = {
                            key: category,
                            data: {},
                        }
                        commit(SET_SHOP_CONFIG, emptyData)
                    }
                    const payload = {
                        key: category,
                        data: data.data,
                    }
                    commit(ADD_NEW_CONFIG, payload)
                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', CREATE_SHOP_CONFIG, {
                        root: true,
                    })
                })
            })
        },
        updateConfig ({ commit, state }, params) {
            let item = state.shopConfigs[params.category].find((item) => item.configurationId === params.id)
            if (Array.isArray(item.configurationData)) {
                item.configurationData = {}
            }
            item = item.configurationData
            if (params.keys.length > 0) {
                params.keys.filter(element => element).forEach((key) => {
                    key = key.trim()
                    item = item[key]
                })
            }

            const payload = {
                item: item,
                key: params.itemKey,
                value: params.itemValue,
            }

            commit(ADD_SHOP_CONFIG_ITEM, payload)
        },
        async removeShopConfig ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', REMOVE_SHOP_CONFIG, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.SHOP_CONFIG.REMOVE.replace('{configurationId}', params.id)
                this._vm.$api.post(url, {}).then(({ data }) => {
                    commit(REMOVE_SHOP_CONFIG, params)
                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', REMOVE_SHOP_CONFIG, {
                        root: true,
                    })
                })
            })
        },
        removeConfigKey ({ commit, state }, params) {
            let item = state.shopConfigs[params.category].find((item) => item.configurationId === params.id).configurationData
            const parents = params.label.split(' ').filter((el) => { return el })
            const keyPath = [ ...parents, params.key ]

            const payload = {
                item: item,
                path: keyPath,
            }
            commit(REMOVE_CONFIG_KEY, payload)
        },
    },
    getters: {
        shopConfig (state) {
            return category => state.shopConfigs[category]
        },
    },
}

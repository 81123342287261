import CONFIG from '@root/config'
import client from '@/api/Client'
import eventBus from '@/plugins/eventBus'
import ApiMixin from '@/mixins/Api'
import segmentClient from '@/plugins/api/SegmentClient'
import { mapActions, mapState } from 'vuex'

export default {
    install (Vue, options) {
        this.$vue = Vue
        this.$options = options
        Vue.config.productionTip = false
        Vue.prototype.$bus = eventBus
        Vue.prototype.$api = client
            .withAccessTokenReceiver((accessToken) => {
                this.$options.store.dispatch('auth/updateAuth', accessToken)
            })
            .withErrorInterceptor((error) => {
                if (CONFIG.DEBUG) {
                    console.error(error)
                }

                if (error.response && error.response.status === 401) {
                    eventBus.$emit('userUnauthorized')
                }

                return Promise.reject(error.response)
            })

        Vue.prototype.$segmentClient = segmentClient

        this.initFilters()
        this.initDirectives()
        this.initPlugins()
        this.initMixins()
        this.guard()
    },

    guard () {
        this.$options.router.beforeEach((to, from, next) => {
            let unguarded = to.matched.some(record => record.meta.unguarded)

            if (!unguarded) {
                this.$options.store.dispatch('auth/check')
                this.$options.store.dispatch('auth/guard', { to, from, next })
            } else {
                next()
            }
        })

        this.$options.router.afterEach((to, from) => {
            if (from.name && from.name !== 'LoginView') {
                client.cancelRequests()
            }
        })
    },

    initFilters () {
        this.$vue.filter('capitalize', function (value) {
            if (!value) return ''
            value = value.toString().toLowerCase()
            return value.charAt(0).toUpperCase() + value.slice(1)
        })

        this.$vue.filter('beautify', function (value) {
            if (!value) {
                return value
            }
            value = value.toString()

            return value.toString().charAt(0).toUpperCase() + value.slice(1).replace(/_/g, ' ')
        })

        this.$vue.filter('underlineToSpace', function (value) {
            return value.toString().replace(/_/g, ' ')
        })

        const timeUnits = {
            year: 24 * 60 * 60 * 1000 * 365,
            month: 24 * 60 * 60 * 1000 * 365 / 12,
            day: 24 * 60 * 60 * 1000,
            hour: 60 * 60 * 1000,
            minute: 60 * 1000,
            second: 1000,
        }

        const relativeTimeFormat = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

        this.$vue.filter('asRelativeHumanTime', function (date) {
            const parsedDate = Date.parse(date)
            if (isNaN(date) && !isNaN(parsedDate)) {
                date = new Date(date)
            }
            const now = new Date()
            const elapsed = date - now
            for (let u in timeUnits) {
                if (Math.abs(elapsed) > timeUnits[u] || u === 'second') {
                    return relativeTimeFormat.format(Math.round(elapsed / timeUnits[u]), u)
                }
            }
            return date.toLocaleString()
        })
    },

    initDirectives () {
        this.$vue.directive('click-outside', {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    // here I check that click was outside the el and his children
                    if (!(el === event.target || el.contains(event.target))) {
                        // and if it did, call method provided in attribute value
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener('click', el.clickOutsideEvent)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.clickOutsideEvent)
            },
        })
    },

    initPlugins () {

    },
    initMixins () {
        this.$vue.mixin({
            methods: {
                ...mapActions('loading', [ 'addJob', 'finishJob' ]),
                hasPermission (action) {
                    // @TODO: Remove to enforce permissions
                    return true
                    // const allowedActions = this.$store.state.shop.allowedActions
                    // if (!allowedActions) {
                    //     console.error('YOU DO NOT HAVE ANY ALLOWED ACTIONS!!!')
                    //     return false
                    // }
                    // if (!allowedActions.indexes || !allowedActions.indexes[action]) {
                    //     return false
                    // }
                    // const actionIndex = allowedActions.indexes[action]
                    // if (allowedActions.actions && allowedActions.actions.general && Object.values(allowedActions.actions.general).includes(actionIndex)) {
                    //     return true
                    // }
                    //
                    // if (this.$store.state.shop.selectedShop.applicationName === 'allShops') {
                    //     return false
                    // }
                    //
                    // return allowedActions.actions.perApplication[this.$store.state.shop.selectedShop.applicationName].includes(actionIndex)
                },
            },
            computed: {
                ...mapState('shop', ['selectedShop']),
            },
        })
        this.$vue.mixin(ApiMixin)
    },
}

import CONFIG from '@root/config'
import { SET_ORDER, SET_ORDERLINE, APPROVE_ARTWORK, ARCHIVE_ARTWORK, ATTACH_ARTWORK_TO_ORDERLINE, UPDATE_ARTWORK_STATUS, SET_ORDER_STATUS, UPDATE_ORDERLINE_STATUS, ASSIGN_ORDER, SET_DTP_OVERVIEW, SET_FOLLOWUPS,
    SET_ORDER_QUEUE_STATUS, SET_ORDER_PAYMENTS, SET_ORDER_INVOICE, SET_SUPPLIER, ADD_COMPLAINT_TO_ORDERLINE, UPDATE_ORDERLINE_COMPLAINT,
    SET_CANCELLATION_REASONS, SET_ORDER_ORDERLINE, SET_ORDERLINE_ADDRESS, SET_TURNAROUND_CALCULATION, SET_PRESET_CUSTOMER, SET_NOTE, REMOVE_NOTE,
} from '@/store/mutation-types'
import router from '@/router/router'

export default {
    namespaced: true,
    strict: true,
    state: {
        order: {},
        orderLine: {},
        assignedOrder: null,
        dtpOverview: null,
        followUps: null,
        orderQueueStatus: null,
        turnaroundCalculation: null,
        supplierData: {},
        reasons: [],
        presetCustomer: null,
    },

    mutations: {
        [ SET_ORDER ] (state, data) {
            this._vm.$set(state, 'order', data)
        },
        [ SET_ORDERLINE ] (state, data) {
            this._vm.$set(state, 'orderLine', data)
        },
        [ SET_ORDER_STATUS ] (state, data) {
            this._vm.$set(state.order, 'orderStatus', data)
        },
        [ SET_DTP_OVERVIEW ] (state, data) {
            this._vm.$set(state, 'dtpOverview', data)
        },
        [ SET_FOLLOWUPS ] (state, data) {
            this._vm.$set(state, 'followUps', data)
        },
        [ SET_ORDER_QUEUE_STATUS ] (state, data) {
            this._vm.$set(state, 'orderQueueStatus', data)
        },
        [ SET_SUPPLIER ] (state, data) {
            this._vm.$set(state.supplierData, [state.orderLine.orderLineId], data)
        },
        [ APPROVE_ARTWORK ] (state, id) {
        },
        [ ARCHIVE_ARTWORK ] (state, id) {
            this._vm.$set(state.orderLine.artwork[id], 'artworkStatus', 'ARCHIVED')
        },
        [ ASSIGN_ORDER ] (state, data) {
            this._vm.$set(state, 'assignedOrder', data)
        },
        [ ATTACH_ARTWORK_TO_ORDERLINE ] (state, data) {
            if (!state.orderLine.artwork) {
                this._vm.$set(state.orderLine, 'artwork', {})
            }
            this._vm.$set(state.orderLine.artwork, data.artworkId, data)
        },
        [ ADD_COMPLAINT_TO_ORDERLINE ] (state, data) {
            if (!state.orderLine.complaints || !Array.isArray(state.orderLine.complaints)) {
                this._vm.$set(state.orderLine, 'complaints', [])
            }
            const payload = [...state.orderLine.complaints, data]
            this._vm.$set(state.orderLine, 'complaints', payload)
        },
        [ UPDATE_ORDERLINE_COMPLAINT ] (state, data) {
            this._vm.$set(state.order.orderLines[data.index].complaints, [data.complaintIndex], data.complaint)
            this._vm.$set(state.orderLine.complaints, [data.complaintIndex], data.complaint)
        },
        [ UPDATE_ARTWORK_STATUS ] (state, data) {
            this._vm.$set(state.order.orderLines[data.index].artwork[data.artworkId], 'artworkStatus', data.status)
        },
        [ UPDATE_ORDERLINE_STATUS ] (state, data) {
            this._vm.$set(state.order.orderLines[data.index], 'orderLineStatus', data.status)
        },
        [ SET_ORDER_PAYMENTS ] (state, data) {
            if (!state.order.payments) {
                this._vm.$set(state.order, 'payments', {})
            }

            this._vm.$set(state.order.payments, data.paymentId, data)
        },
        [ SET_ORDER_INVOICE ] (state, data) {
            if (!state.order.invoice) {
                this._vm.$set(state.order, 'invoice', {})
            }

            this._vm.$set(state.order, 'invoice', { ...state.order.invoice, ...data })
        },
        [ SET_CANCELLATION_REASONS ] (state, data) {
            state.reasons = data
        },
        [ SET_ORDER_ORDERLINE ] (state, data) {
            this._vm.$set(state.order.orderLines, [data.index], data.data)
        },
        [ SET_ORDERLINE_ADDRESS ] (state, data) {
            this._vm.$set(state.order.orderLines[data.index].deliveryInfo, 'address', data.address)
        },
        [ SET_TURNAROUND_CALCULATION ] (state, data) {
            this._vm.$set(state, 'turnaroundCalculation', data)
        },
        [ SET_PRESET_CUSTOMER ] (state, data) {
            this._vm.$set(state, 'presetCustomer', data)
        },
        [ SET_NOTE ] (state, data) {
            if (!state.order.notes) {
                this._vm.$set(state.order, 'notes', {})
            }
            this._vm.$set(state.order.notes, data.noteId, data.data)
        },
        [ REMOVE_NOTE ] (state, noteId) {
            this._vm.$delete(state.order.notes, noteId)
        },
    },

    actions: {
        async fetchOrder ({ dispatch, commit, state, getters }, params) {
            if (params.purgeOrderlines && state.order) {
                const order = state.order
                order.orderLines = []
                commit(SET_ORDER, order)
            }

            if (state.order.orderId !== params.orderId) {
                commit(SET_ORDER, {})
                commit(SET_ORDERLINE, {})
            }

            dispatch('loading/addJob', SET_ORDER, {
                root: true,
            })
            const routeAccountId = router.currentRoute.params.accountId
            const customerId = routeAccountId || params.accountId
            const route = CONFIG.API.ROUTES.ORDERS.GET
                .replace('{orderId}', params.orderId)
                .replace('{accountId}', customerId)

            await this._vm.$api.get(route).then(({ data }) => {
                commit(SET_ORDER, data.data)
                if (params.currentOrderline) {
                    dispatch('setOrderLine', params.currentOrderline)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_ORDER, {
                    root: true,
                })
            })
        },

        setOrder ({ dispatch, commit }, params) {
            commit(SET_ORDER, params.data)
            if (params.currentOrderline) {
                dispatch('setOrderLine', params.currentOrderline)
            }
        },

        setOrderLine ({ state, commit }, id) {
            if (this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)) {
                commit(ASSIGN_ORDER, this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY))
            }
            commit(SET_ORDERLINE, {})
            if (!Object.keys(state.order).length) {
                return
            }
            state.order.orderLines.forEach((item) => {
                if (item.orderLineId === id) {
                    commit(SET_ORDERLINE, item)
                }
            })
        },

        updateOrderOrderline ({ state, commit, dispatch }, payload) {
            const ids = state.order.orderLines.map((e) => e.orderLineId)
            const index = ids.findIndex(item => item === payload.id)
            const newItem = {
                ...state.order.orderLines[index],
                ...payload.data,
            }
            commit(SET_ORDER_ORDERLINE, { index: index, data: newItem })
            dispatch('setOrderLine', payload.id)
        },

        async updateOrderlines ({ state, commit, dispatch }, params) {
            dispatch('loading/addJob', params.url, {
                root: true,
            })

            return new Promise((resolve, reject) => {
                const payload = {
                    newOrderlines: params.new,
                }

                if (params.reason) {
                    payload.reason = params.reason
                }

                this._vm.$api.post(params.url, payload).then(({ data }) => {
                    dispatch('fetchOrder', {
                        orderId: params.orderId,
                    })

                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', params.url, {
                        root: true,
                    })
                })
            })
        },

        async fetchOrderArtworkStatus ({ dispatch, commit }, params) {
            const route = CONFIG.API.ROUTES.ORDERS.ARTWORK_STATUS.replace('{orderId}', params.orderId)

            dispatch('loading/addJob', route, {
                root: true,
            })
            await this._vm.$api.get(route, params.payload).then(({ data }) => {
                commit(SET_ORDER_QUEUE_STATUS, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', route, {
                    root: true,
                })
            })
        },

        async approveArtwork ({ dispatch, commit }, params) {
            dispatch('loading/addJob', APPROVE_ARTWORK, {
                root: true,
            })

            return new Promise((resolve, reject) => {
                const route = CONFIG.API.ROUTES.ARTWORKS.APPROVE.replace('{accountId}', params.accountId).replace('{orderId}', params.orderId)
                this._vm.$api.post(route, params.payload).then(({ data }) => {
                    const artworkId = data.data.response.data.artworkId
                    commit(APPROVE_ARTWORK, artworkId)
                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', APPROVE_ARTWORK, {
                        root: true,
                    })
                })
            })
        },

        async archiveArtwork ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', ARCHIVE_ARTWORK, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ARTWORKS.ARCHIVE.replace('{accountId}', params.accountId).replace('{orderId}', params.orderId)
            await this._vm.$api.post(route, params.payload).then(({ data }) => {
                const index = state.orderLine.artwork.findIndex(e => e.artworkId === data.data.response.data.artworkId)
                commit(ARCHIVE_ARTWORK, index)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', ARCHIVE_ARTWORK, {
                    root: true,
                })
            })
        },

        async archiveAllArtworks ({ dispatch, commit }, params) {
            dispatch('loading/addJob', ARCHIVE_ARTWORK, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ARTWORKS.ARCHIVE_ALL_ARTWORKS.replace('{accountId}', params.accountId).replace('{orderId}', params.orderId)
            await this._vm.$api.post(route, params.payload).then(({ data }) => {
                data.data.forEach((item, index) => {
                    commit(ARCHIVE_ARTWORK, index)
                })
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', ARCHIVE_ARTWORK, {
                    root: true,
                })
            })
        },

        async attachArtworkToOrderLine ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', ATTACH_ARTWORK_TO_ORDERLINE, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ARTWORKS.ATTACH.replace('{accountId}', params.accountId).replace('{orderId}', params.orderId)
            return new Promise((resolve, reject) => {
                this._vm.$api.post(route, params.payload).then(({ data }) => {
                    if (!state.orderLine.artwork) {
                        this._vm.$set(state.orderLine, 'artwork', [])
                    }
                    commit(ATTACH_ARTWORK_TO_ORDERLINE, data.data.artwork)
                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', ATTACH_ARTWORK_TO_ORDERLINE, {
                        root: true,
                    })
                })
            })
        },

        async cancelOrder ({ dispatch, commit }, params) {
            dispatch('loading/addJob', SET_ORDER_STATUS, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ORDERS.CANCEL.replace('{accountId}', params.accountId).replace('{orderId}', params.orderId)
            await this._vm.$api.post(route).then(({ data }) => {
                commit(SET_ORDER_STATUS, data.data.orderStatus)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_ORDER_STATUS, {
                    root: true,
                })
            })
        },

        async cancelOrderLine ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', params.url, {
                root: true,
            })
            await this._vm.$api.post(params.url, { reason: params.reason }).then(({ data }) => {
                const index = state.order.orderLines.map(e => e.orderLineId).indexOf(data.data.orderLineId)
                commit(UPDATE_ORDERLINE_STATUS, {
                    index: index,
                    status: data.data.orderLineStatus,
                })
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', params.url, {
                    root: true,
                })
            })
        },
        async fetchCancellationReasons ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', params.url, {
                root: true,
            })
            if (Array.isArray(state.reasons) && state.reasons.length) {
                return
            }
            const route = CONFIG.API.ROUTES.ORDERS.GET_CANCEL_REASONS.replace('{orderId}', params.orderId)
            await this._vm.$api.post(route, { reason: params.reason }).then(({ data }) => {
                commit(SET_CANCELLATION_REASONS, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', params.url, {
                    root: true,
                })
            })
        },

        async addOrderLineToOrder ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', params.url, {
                root: true,
            })
            await this._vm.$api.post(params.url, params.payload).then(({ data }) => {
                dispatch('setOrder', { data: data.data })
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', params.url, {
                    root: true,
                })
            })
        },

        async assignFirstOrderToAgent ({ dispatch, commit, state, rootState }, params) {
            if (this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)) {
                commit(ASSIGN_ORDER, this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY))
                return
            }
            dispatch('loading/addJob', ASSIGN_ORDER, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ORDERS.ASSIGN_FIRST.replace('{accountId}', params.accountId)
            await this._vm.$api.post(route, { agentName: `${rootState.auth.user.firstName} ${rootState.auth.user.lastName}` }).then(({ data }) => {
                const assignTask = data.data.find((event) => { return event.event === 'ArtworkTaskWasAssigned' })
                const info = {
                    orderNumber: assignTask.response.orderNumber,
                    orderId: assignTask.response.orderId,
                    dateTo: assignTask.response.artworkTask.dateTo,
                    accountId: data.data[0].response.data.customerId,
                    orderLineNumbers: data.data.map(orderLine => orderLine.response.orderLineNumber),
                    orderLineIds: data.data.map(orderLine => orderLine.response.orderLineId),
                    applicationId: assignTask.response.applicationId,
                }
                if (!this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)) {
                    this._vm.$cookies.set(CONFIG.COOKIES.ASSIGNED_ORDER.KEY, info, 900)
                    commit(ASSIGN_ORDER, info)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', ASSIGN_ORDER, {
                    root: true,
                })
            })
        },

        async assignOrderToAgent ({ dispatch, commit, state }, params) {
            if (this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)) {
                commit(ASSIGN_ORDER, this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY))
                return
            }
            dispatch('loading/addJob', ASSIGN_ORDER, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ORDERS.ASSIGN.replace('{orderId}', params.orderId).replace('{accountId}', params.accountId).replace('{customerId}', params.customerId)
            return new Promise((resolve, reject) => {
                this._vm.$api.post(route, { agentName: params.name }).then(({ data }) => {
                    const assignTask = data.data.find((event) => { return event.event === 'ArtworkTaskWasAssigned' })
                    const info = {
                        orderId: assignTask.response.orderId,
                        dateTo: assignTask.response.artworkTask.dateTo,
                        orderLineIds: data.data.map(orderLine => orderLine.response.orderLineId),
                    }
                    if (!this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)) {
                        this._vm.$cookies.set(CONFIG.COOKIES.ASSIGNED_ORDER.KEY, info, 900)
                        commit(ASSIGN_ORDER, info)
                    }
                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', ASSIGN_ORDER, {
                        root: true,
                    })
                })
            })
        },

        async unassignOrderFromAgent ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', ASSIGN_ORDER, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ORDERS.UNASSIGN.replace('{orderId}', params.orderId).replace('{accountId}', params.accountId).replace('{customerId}', params.customerId)
            await this._vm.$api.post(route, { reason: params.reason }).then(({ data }) => {
                if (this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)) {
                    this._vm.$cookies.remove(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)
                    commit(ASSIGN_ORDER, null)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', ASSIGN_ORDER, {
                    root: true,
                })
            })
        },

        async skipOrder ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', ASSIGN_ORDER, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ORDERS.SKIP.replace('{orderId}', params.orderId).replace('{accountId}', params.accountId).replace('{customerId}', params.customerId)
            await this._vm.$api.post(route, { reason: params.reason }).then(({ data }) => {
                if (this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)) {
                    this._vm.$cookies.remove(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)
                    commit(ASSIGN_ORDER, null)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', ASSIGN_ORDER, {
                    root: true,
                })
            })
        },

        checkAssignedOrder ({ commit }) {
            if (!this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY)) {
                commit(ASSIGN_ORDER, null)
                return
            }
            commit(ASSIGN_ORDER, this._vm.$cookies.get(CONFIG.COOKIES.ASSIGNED_ORDER.KEY))
        },

        async fetchDtpOverview ({ dispatch, commit, rootState }) {
            dispatch('loading/addJob', SET_DTP_OVERVIEW, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ORDERS.QUEUE_STATUS.replace('{accountId}', rootState.auth.user.accountId)

            await this._vm.$api.get(route).then(({ data }) => {
                commit(SET_DTP_OVERVIEW, data.data.overview)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_DTP_OVERVIEW, {
                    root: true,
                })
            })
        },

        async fetchFollowups ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_FOLLOWUPS, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.ORDERS.FOLLOWUP.OVERVIEW
            await this._vm.$api.get(route).then(({ data }) => {
                const payload = []
                for (let key in data.data['en-UK']) {
                    if (data.data['en-UK'].hasOwnProperty(key)) {
                        Object.entries(data.data['en-UK'][key]).forEach((item) => {
                            payload.push({
                                artworkFollowupAction: key,
                                label: item[0],
                                message: item[1],
                            })
                        })
                    }
                }

                commit(SET_FOLLOWUPS, payload)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_FOLLOWUPS, {
                    root: true,
                })
            })
        },

        async fetchSupplierData ({ dispatch, commit, state }) {
            const url = CONFIG.API.ROUTES.SUPPLIER.OVERVIEW
            const payload = {
                'supplierId': state.orderLine.product.producer.supplierId,
            }

            dispatch('loading/addJob', url, {
                root: true,
            })
            await this._vm.$api.post(url, payload).then(({ data }) => {
                commit(SET_SUPPLIER, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', url, {
                    root: true,
                })
            })
        },

        updateArtworkStatus ({ dispatch, commit, state }, params) {
            const index = state.order.orderLines.map(e => e.orderLineId).indexOf(params.orderLineId)
            commit(UPDATE_ARTWORK_STATUS, {
                index: index,
                artworkId: params.artworkId,
                status: params.status,
            })
        },

        async setOrderLineStatus ({ dispatch, commit }, params) {
            const url = params.url
            const payload = {
                orderLineStatus: params.status,
            }

            dispatch('loading/addJob', url, {
                root: true,
            })
            await this._vm.$api.post(url, payload).then(({ data }) => {
                if (params.orderId) {
                    dispatch('fetchOrder', {
                        orderId: params.orderId,
                        currentOrderline: params.currentOrderline || null,
                    })
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', url, {
                    root: true,
                })
            })
        },

        processFollowup ({ dispatch, commit, state }, params) {
            params.data.forEach((item, index) => {
                const data = item.response.data
                if (item.event === 'ArtworkWasDeclined') {
                    dispatch('updateArtworkStatus', {
                        orderLineId: data.orderLineId,
                        artworkId: data.artworkId,
                        status: 'REJECTED',
                    })
                    dispatch('setOrderLineById', data.orderLineId)
                }
                if (item.event === 'OrderLineStatusWasSet') {
                    const index = state.order.orderLines.map(e => e.orderLineId).indexOf(data.orderLineId)
                    commit(UPDATE_ORDERLINE_STATUS, {
                        index: index,
                        status: data.orderLineStatus,
                    })
                    dispatch('setOrderLineById', data.orderLineId)
                }
                if (item.event === 'ArtworkProofWasMadeAvailable') {
                    dispatch('updateArtworkStatus', {
                        orderLineId: data.orderLineId,
                        artworkId: data.artworkId,
                        status: 'PROOF',
                    })
                    dispatch('setOrderLineById', data.orderLineId)
                }
            })
        },

        updateComplaint ({ state, commit }, params) {
            const id = state.orderLine.orderLineId
            const index = state.order.orderLines.map(e => e.orderLineId).indexOf(id)
            const complaintIndex = state.orderLine.complaints.findIndex(e => e.complaintId === params.complaintId)
            commit(UPDATE_ORDERLINE_COMPLAINT, {
                index,
                complaintIndex,
                complaint: params,
            })
        },

        addComplaintToOrderLine ({ commit }, params) {
            commit(ADD_COMPLAINT_TO_ORDERLINE, params)
        },

        setOrderPayments ({ commit, state }, params) {
            const item = params.data.find((e) => e.event === 'OrderPaymentWasAdded').response.data
            commit(SET_ORDER_PAYMENTS, item.payment)
        },

        setOrderInvoice ({ commit }, params) {
            const item = params.data.find((e) => e.event === 'InvoiceStatusWasSet').response.data
            commit(SET_ORDER_INVOICE, item.invoice)
        },

        updateOrderLineAddress ({ commit }, params) {
            commit(SET_ORDERLINE_ADDRESS, params)
        },

        setPresetCustomer ({ commit }, params) {
            commit(SET_PRESET_CUSTOMER, params)
        },

        getTurnaroundCalculation ({ commit, state, dispatch }) {
            commit(SET_TURNAROUND_CALCULATION, null)
            if (!state.orderLine.product) {
                return
            }

            const route = CONFIG.API.ROUTES.SUPPLIER.GET_TURNAROUND_CALCULATION.replace('{turnaroundHash}', state.orderLine.product.turnaroundHash)
            dispatch('loading/addJob', route, {
                root: true,
            })

            this._vm.$api.get(route).then(({ data }) => {
                commit(SET_TURNAROUND_CALCULATION, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', route, {
                    root: true,
                })
            })
        },

        setNote ({ commit }, params) {
            commit(SET_NOTE, { data: params.data, noteId: params.noteId })
        },

        removeNote ({ commit }, noteId) {
            commit(REMOVE_NOTE, noteId)
        },
    },

    getters: {
        artworks: (state) => {
            if (state.orderLine.artwork) {
                const artworks = Object.values(state.orderLine.artwork)

                artworks.sort(function (majorDate, minorDate) {
                    if (majorDate.dateCreated < minorDate.dateCreated) {
                        return 1
                    }
                    if (majorDate.dateCreated > minorDate.dateCreated) {
                        return -1
                    }
                    return 0
                })
                return artworks
            }
            return []
        },
        activeArtwork: (state) => {
            const badArtworkStatuses = ['ARCHIVED', 'REJECTED', 'DECLINED']
            let artworks = []
            if (state.orderLine.artwork) {
                artworks = Object.values(state.orderLine.artwork).filter((e) => !badArtworkStatuses.includes(e.artworkStatus))
                if (!artworks.length) {
                    return null
                }

                artworks.sort(function (majorDate, minorDate) {
                    if (majorDate.dateCreated < minorDate.dateCreated) {
                        return 1
                    }
                    if (majorDate.dateCreated > minorDate.dateCreated) {
                        return -1
                    }
                    return 0
                })
            }

            if (!artworks.length) {
                return
            }

            return artworks[0]
        },
        artworkStatus: (state, rootGetters) => {
            const artwork = rootGetters.activeArtwork

            if (!artwork) {
                return
            }

            return artwork.artworkStatus
        },
        orderLineHasCpdf: (state) => {
            if (!state.orderLine.artwork) {
                return false
            }
            return Object.values(state.orderLine.artwork).some((artwork) => {
                return artwork.artworkType === 'CPDF'
            })
        },
        orderLineQueueStatus (state) {
            if (!state.orderQueueStatus) {
                return null
            }

            return orderLineId => state.orderQueueStatus.find((e) => e.orderLineId === orderLineId)
        },
        orderLineQueueStatusByNumber (state) {
            if (!state.orderQueueStatus) {
                return null
            }

            return orderLineNumber => state.orderQueueStatus.find((e) => e.orderLineNumber === orderLineNumber)
        },

        totalAmountPaid (state) {
            if (!state.order.totalPaid) {
                return
            }
            return (parseFloat(state.order.totalPaid.value) / 100).toFixed(2)
        },

        orderNotes (state) {
            if (!state.order.notes) {
                return []
            }

            const notes = Object.values(state.order.notes)
            return notes.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
        },
    },
}

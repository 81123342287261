import VuexPersistence from 'vuex-persist'
import modules from './modules'

export default {
    modules,
    plugins: [new VuexPersistence({
        reducer: (state) => ({
            shop: state.shop,
        }),
    }).plugin],
}

import axios from 'axios'

class SegmentClient {
    constructor ($axios) {
        this.$axios = $axios
        this.section = 'SEGMENT'
    }

    getPageId (vueContext) {
        if (vueContext.productData) return vueContext.productData.name
        if (vueContext.content && vueContext.content.title) return vueContext.content.title
        return vueContext.$options.name
    }

    getLoad (vueContext, payload) {
        const baseLoad = {
            pageId: this.getPageId(vueContext),
            url: window.location.origin + vueContext.$route.fullPath,
        }
        const mergedLoad = Object.assign(baseLoad, payload)
        mergedLoad.pageId = mergedLoad.pageId.trim()
        return mergedLoad
    }

    sendAction (vueContext, name, payload, callback) {
        return new Promise((resolve, reject) => {
            this.$axios.post('/track/action/' + encodeURIComponent(name), payload, this.getOptions(vueContext, payload.userId)).then(({ data }) => {
                if (callback) {
                    callback(data)
                }
                resolve(data)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    sendPageView (vueContext, payload, callback) {
        return new Promise((resolve, reject) => {
            const mergedPayload = this.getLoad(vueContext, payload)
            this.$axios.post('/track/view/' + encodeURIComponent(mergedPayload.pageId), mergedPayload, this.getOptions(vueContext, mergedPayload.userId)).then(({ data }) => {
                if (callback) {
                    callback(data)
                }
                resolve(data)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    getOptions (vueContext, userId) {
        return {
            headers: {
                context: JSON.stringify(this.getContext(vueContext, userId)),
            },
        }
    }

    getContext (vueContext, userId) {
        const segmentContext = {
            userId: userId,
            userAgent: process.server ? this.req.headers['user-agent'] : navigator.userAgent,
            ip: null,
            campaign: {},
            page: {
                path: null,
            },
        }

        if (process.server) {
            segmentContext.ip = this.req.connection.remoteAddress || this.req.socket.remoteAddress
        }

        if (vueContext && vueContext.$route) {
            segmentContext.page = {
                path: vueContext.$route.fullPath || '',
                referrer: null,
                search: null,
                title: this.getPageId(vueContext) || '',
                // url: 'https://' + CONFIG.CLIENT.DOMAIN + vueContext.$route.fullPath,
            }

            if (vueContext.$route.query) {
                segmentContext.campaign = {
                    content: vueContext.$route.query.utm_content || null,
                    medium: vueContext.$route.query.utm_medium || null,
                    name: vueContext.$route.query.utm_campaign || null,
                    source: vueContext.$route.query.utm_source || null,
                    term: vueContext.$route.query.utm_term || null,
                }
            }
        }

        return segmentContext
    }
}

export default new SegmentClient(axios)

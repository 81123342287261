import CONFIG from '@root/config'
import { SET_CATEGORIES, ADD_CATEGORY } from '@/store/mutation-types'

export default {
    namespaced: true,
    strict: true,
    state: {
        categories: [],
    },

    mutations: {
        [ SET_CATEGORIES ] (state, data) {
            this._vm.$set(state, 'categories', data)
        },
        [ ADD_CATEGORY ] (state, data) {
            state.categories.push(data)
        },
    },

    actions: {
        async fetchCategories ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_CATEGORIES, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.CATEGORIES.OVERVIEW

            await this._vm.$api.get(route).then(({ data }) => {
                commit(SET_CATEGORIES, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_CATEGORIES, {
                    root: true,
                })
            })
        },

        async addCategory ({ dispatch, commit }, params) {
            const route = CONFIG.API.ROUTES.CATEGORIES.ADD
            dispatch('loading/addJob', route, {
                root: true,
            })
            await this._vm.$api.post(route, params).then(({ data }) => {
                data.data.categories = []
                commit(ADD_CATEGORY, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', route, {
                    root: true,
                })
            })
        },

        async addChildCategory ({ dispatch, state }, params) {
            const route = CONFIG.API.ROUTES.CATEGORIES.ADD
            dispatch('loading/addJob', route, {
                root: true,
            })
            await dispatch('recursiveAdd', {
                data: state.categories,
                id: params.categoryId,
                route: route,
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', route, {
                    root: true,
                })
            })
        },

        async updateCategory ({ dispatch, commit }, params) {
            const route = CONFIG.API.ROUTES.CATEGORIES.UPDATE
            dispatch('loading/addJob', route, {
                root: true,
            })
            await this._vm.$api.post(route, params).then(() => {
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', route, {
                    root: true,
                })
            })
        },

        async removeCategory ({ dispatch, state }, params) {
            const route = CONFIG.API.ROUTES.CATEGORIES.REMOVE.replace('{categoryId}', params.categoryId)
            dispatch('loading/addJob', route, {
                root: true,
            })

            await dispatch('recursiveRemove', {
                data: state.categories,
                id: params.categoryId,
                route: route,
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', route, {
                    root: true,
                })
            })
        },

        async addProductToCategory ({ dispatch }, params) {
            dispatch('loading/addJob', params.url, {
                root: true,
            })
            const payload = { product: params.product, marginType: params.marginType, marginValue: params.marginValue }
            await this._vm.$api.post(params.url, payload).then(({ data }) => {
                console.log(data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', params.url, {
                    root: true,
                })
            })
        },

        async removeProductFromCategory ({ dispatch }, params) {
            dispatch('loading/addJob', params.url, {
                root: true,
            })
            await this._vm.$api.post(params.url, params.product).then(({ data }) => {
                console.log(data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', params.url, {
                    root: true,
                })
            })
        },

        recursiveRemove ({ dispatch }, params) {
            params.data.forEach((category) => {
                if (category.categoryId === params.id) {
                    this._vm.$api.post(params.route, category).then(() => {
                        const index = params.data.indexOf(category)
                        params.data.splice(index, 1)
                    })
                }

                if (category.categories && category.categories.length > 0) {
                    dispatch('recursiveRemove', {
                        data: category.categories,
                        id: params.id,
                        route: params.route,
                    })
                }
            })
        },

        recursiveAdd ({ dispatch }, params) {
            params.data.forEach((category) => {
                if (category.categoryId === params.id) {
                    const payload = {
                        name: 'Category',
                        margin: 0,
                        marginType: 'STATIC',
                        parentId: category.categoryId,
                        catalogId: 'd4a000ee-76e3-4b62-b06c-1da7e4eae9fb', // @TODO: need to implement catalogs
                    }
                    this._vm.$api.post(params.route, payload).then(({ data }) => {
                        data.data.categories = []
                        category.categories.push(data.data)
                    })
                    return
                }
                if (category.categories && category.categories.length > 0) {
                    dispatch('recursiveAdd', {
                        data: category.categories,
                        id: params.id,
                        route: params.route,
                    })
                }
            })
        },

    },
}

import CONFIG from '@root/config'
import { SET_SHOP_AGREEMENTS, SET_AGREEMENT } from '@/store/mutation-types'

export default {
    namespaced: true,
    state: {
        shopAgreements: null,
        agreement: null,
    },
    mutations: {
        [ SET_SHOP_AGREEMENTS ] (state, data) {
            this._vm.$set(state, 'shopAgreements', data)
        },
        [ SET_AGREEMENT ] (state, data) {
            this._vm.$set(state, 'agreement', data)
        },
    },
    actions: {
        async fetchShopAgreements ({ dispatch, commit, state }) {
            dispatch('loading/addJob', SET_SHOP_AGREEMENTS, {
                root: true,
            })
            commit(SET_SHOP_AGREEMENTS, null)

            const route = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.GET_AGREEMENTS
            await this._vm.$api.get(route).then(({ data }) => {
                commit(SET_SHOP_AGREEMENTS, data.data)
                if (!state.agreement) {
                    dispatch('setShopAgreement', data.data[0])
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_SHOP_AGREEMENTS, {
                    root: true,
                })
            })
        },

        setShopAgreement ({ commit, state }, params) {
            commit(SET_AGREEMENT, params)
        },
    },
}

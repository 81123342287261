<template>
    <div id="app" class="c-app">

        <div class="header">
            <div class="d-flex header__content position-relative">
                <top-menu class="flex-grow-1 d-flex align-items-center" @toggleMenu="toggleMenu" />
                <account-menu class="flex-grow-1 d-flex justify-content-end align-items-center" />
                <mercur-progress-bar data-e2e="loading" indeterminate v-if="getPendingJobs.length" />
            </div>
        </div>

        <div class="app">
            <div class="d-flex">
                <div class="app__sidebar bg-white shadow" :class="sidebarVisible && isAuthenticated ? '' : 'app__sidebar-closed'" >
                    <sidebar v-if="isAuthenticated"></sidebar>
                </div>
                <div class="app__content flex-grow-1" :class="sidebarVisible && isAuthenticated ? 'app__content-margin' : ''">
                    <div v-if="isAuthenticated">
                        <breadcrumbs :routeview="$refs.routeview" class="o-breadcrumbs"></breadcrumbs>
                    </div>
                    <transition name="fade" mode="out-in" appear>
                        <router-view ref="routeview"></router-view>
                    </transition>
                </div>
            </div>
        </div>
        <mercur-alert data-e2e="alert" :notification.sync="notification">
        </mercur-alert>
        <iframe class="downloadiframe" ref="downloadiframe"></iframe>
    </div>
</template>

<style lang="scss">
    @import 'assets/scss/main';
</style>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Sidebar from '@/components/elements/Sidebar'
import TopMenu from '@/components/elements/TopMenu'
import AccountMenu from '@/components/elements/AccountMenu'
import Breadcrumbs from '@/components/elements/Breadcrumbs'

export default {
    name: 'App',
    components: { Sidebar, TopMenu, AccountMenu, Breadcrumbs },
    data () {
        return {
            menuVisible: true,
            sidebarVisible: true,
            notificationShow: false,
            notification: {
                show: false,
            },
            notificationTimeout: null,
            loading: false,
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapGetters('auth', ['isAuthenticated']),
        ...mapGetters('loading', ['getPendingJobs']),
        appContentStyling () {
            return this.$route.matched.reduce((obj, currentItem) => {
                if (currentItem.meta.style) {
                    obj = {
                        ...obj,
                        ...currentItem.meta.style,
                    }
                }
                return obj
            }, {})
        },
    },
    methods: {
        ...mapActions('locale', ['initLocale']),
        ...mapActions('session', ['initSession']),
        ...mapActions('shop', ['initShop', 'fetchCurrencyConversionRates']),
        show () {
            this.notification.show = true
        },
        toggleMenu () {
            this.menuVisible = !this.menuVisible
        },
        toggleSidebar () {
            this.sidebarVisible = !this.sidebarVisible
        },
    },
    created () {
        this.initLocale()
        this.initSession()
        this.initShop()
        if (this.isAuthenticated) {
            this.fetchCurrencyConversionRates()
        }
        this.$root.$on('notification:global', (event) => {
            this.notification = event
            this.notificationShow = true
        })

        this.$bus.$on('DOWNLOAD_URL_RESOLVED', url => {
            this.$refs.downloadiframe.src = url
        })

        if (window.innerWidth < 1600) {
            this.menuVisible = false
        }
        this.$root.$on('toggleMenu', this.toggleSidebar)
    },
    mounted () {
        this.$bus.$on('userUnauthorized', () => {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push({ name: 'LoginView' })
                this.$root.$emit('notification:global', {
                    message: 'Your session has expired.',
                    type: 'error',
                })
            })
        })
    },
}
</script>

import { SET_AGENTS } from '@/store/mutation-types'
import CONFIG from '@root/config'

export default {
    namespaced: true,

    state: {
        agents: null,
    },

    mutations: {
        [ SET_AGENTS ] (state, agents) {
            this._vm.$set(state, 'agents', agents)
        },
    },

    actions: {
        async fetchAllAgents ({ commit, dispatch }) {
            dispatch('loading/addJob', SET_AGENTS, {
                root: true,
            })
            const route = CONFIG.API.ROUTES.AGENTS.OVERVIEW
            return this._vm.$api.post(route, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                commit(SET_AGENTS, data.data)
                return data.data
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_AGENTS, {
                    root: true,
                })
            })
        },
    },
    getters: {
        getAllAgents (state) {
            return state.agents
        },
        getAgentsSortedByFirstName (state) {
            if (!state.agents) {
                return null
            }
            return state.agents.sort((agentA, agentB) => {
                return agentA.firstName.localeCompare(agentB.firstName)
            })
        },
    },
}

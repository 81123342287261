// general

// user
export const SET_USER = 'SET_USER'
export const SET_LAST_VISITED_PATH = 'SET_PATH'
export const SET_ALLOWED_ACTIONS = 'SET_ALLOWED_ACTIONS'
export const SET_USER_ADDITIONAL_DATA = 'SET_USER_ADDITIONAL_DATA'

// loading
export const ADD_JOB = 'ADD_JOB'
export const FINISH_JOB = 'FINISH_JOB'

// orders
export const SET_ORDER = 'SET_ORDER'
export const SET_ORDERLINE = 'SET_ORDERLINE'
export const APPROVE_ARTWORK = 'APPROVE_ARTWORK'
export const ARCHIVE_ARTWORK = 'ARCHIVE_ARTWORK'
export const ATTACH_ARTWORK_TO_ORDERLINE = 'ATTACH_ARTWORK_TO_ORDERLINE'
export const UPDATE_ARTWORK_STATUS = 'UPDATE_ARTWORK_STATUS'
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY'
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'
export const UPDATE_ORDERLINE_STATUS = 'UPDATE_ORDERLINE_STATUS'
export const ASSIGN_ORDER = 'ASSIGN_ORDER'
export const SET_DTP_OVERVIEW = 'SET_DTP_OVERVIEW'
export const SET_FOLLOWUPS = 'SET_FOLLOWUPS'
export const SET_ORDER_QUEUE_STATUS = 'SET_ORDER_QUEUE_STATUS'
export const SET_ORDER_PAYMENTS = 'SET_ORDER_PAYMENTS'
export const SET_ORDER_INVOICE = 'SET_ORDER_INVOICE'
export const SET_SUPPLIER = 'SET_SUPPLIER'
export const SET_CANCELLATION_REASONS = 'SET_CANCELLATION_REASONS'
export const SET_ORDER_ORDERLINE = 'SET_ORDER_ORDERLINE'
export const SET_ORDERLINE_ADDRESS = 'SET_ORDERLINE_ADDRESS'
export const SET_TURNAROUND_CALCULATION = 'SET_TURNAROUND_CALCULATION'
export const SET_PRESET_CUSTOMER = 'SET_PRESET_CUSTOMER'
export const SET_NOTE = 'SET_NOTE'
export const REMOVE_NOTE = 'REMOVE_NOTE'

// customers
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_CUSTOMER_BILLING_ADDRESS = 'SET_CUSTOMER_BILLING_ADDRESS'
export const SET_CUSTOMER_SHIPPING_ADDRESS = 'SET_CUSTOMER_SHIPPING_ADDRESS'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const SET_ACTIVE_DTP_AGENT = 'SET_ACTIVE_DTP_AGENT'
export const SET_ADRESSES = 'SET_ADRESSES'

// complaints
export const ADD_COMPLAINT_TO_ORDERLINE = 'ADD_COMPLAINT_TO_ORDERLINE'
export const UPDATE_ORDERLINE_COMPLAINT = 'UPDATE_ORDERLINE_COMPLAINT'

// shop
export const SET_SHOP = 'SET_SHOP'
export const SET_SHOPS = 'SET_SHOPS'
export const SET_CURRENCY_RATES = 'SET_CURRENCY_RATES'
export const SET_TEMPORARY_SHOP = 'SET_TEMPORARY_SHOP'
export const SET_VAT_PROFILES = 'SET_VAT_PROFILES'

// shop configuration
export const SET_SHOP_CONFIG = 'SET_SHOP_CONFIG'
export const ADD_SHOP_CONFIG_ITEM = 'ADD_SHOP_CONFIG_ITEM'
export const CREATE_SHOP_CONFIG = 'CREATE_SHOP_CONFIG'
export const ADD_NEW_CONFIG = 'ADD_NEW_CONFIG'
export const SET_CURRENT_SHOP = 'SET_CURRENT_SHOP'
export const CLEAR_SHOP_CONFIG = 'CLEAR_SHOP_CONFIG'
export const REMOVE_SHOP_CONFIG = 'REMOVE_SHOP_CONFIG'
export const REMOVE_CONFIG_KEY = 'REMOVE_CONFIG_KEY'

// products
export const SET_SHOP_AGREEMENTS = 'SET_SHOP_AGREEMENTS'
export const SET_AGREEMENT = 'SET_AGREEMENT'

// categories
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const ADD_CATEGORY = 'ADD_CATEGORY'

// product feed
export const SET_PRODUCT_FEEDS = 'SET_PRODUCT_FEEDS'
export const SET_CONFIGURATIONS = 'SET_CONFIGURATIONS'
export const SET_FIELDS = 'SET_FIELDS'
export const SET_CURRENT_PRODUCT_FEED = 'SET_CURRENT_PRODUCT_FEED'
export const SET_CURRENT_CONFIGURATION = 'SET_CURRENT_CONFIGURATION'
export const SET_CURRENT_FIELD = 'SET_CURRENT_FIELD'
export const SET_PRODUCT_FEED_DETAILS = 'SET_PRODUCT_FEED_DETAILS'

// agents
export const SET_AGENTS = 'SET_AGENTS'

import auth from './auth'
import loading from './loading'
import locale from './locale'
import session from './session'
import shop from './shop'
import order from './order'
import customer from './customer'
import shopconfig from './shopconfig'
import products from './products'
import category from './category'
import catalog from './catalog'
import productfeed from './productfeed'
import agents from './agents'

export default {
    auth,
    shop,
    loading,
    locale,
    session,
    order,
    customer,
    shopconfig,
    products,
    category,
    catalog,
    productfeed,
    agents,
}

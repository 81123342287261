import CONFIG from '@root/config'
import { SET_SHOP, SET_SHOPS, SET_ALLOWED_ACTIONS, SET_CURRENCY_RATES, SET_TEMPORARY_SHOP, SET_VAT_PROFILES } from '@/store/mutation-types'

export default {
    namespaced: true,
    state: {
        selectedShop: null,
        shops: [],
        allowedActions: null,
        currencyConversionRates: null,
        applicationVatProfiles: null,
        temporaryShop: null,
    },
    mutations: {
        [ SET_SHOP ] (state, shop) {
            state.selectedShop = shop
        },
        [ SET_SHOPS ] (state, shops) {
            this._vm.$set(state, 'shops', shops)
        },
        [ SET_ALLOWED_ACTIONS ] (state, actions) {
            this._vm.$set(state, 'allowedActions', actions)
        },
        [ SET_CURRENCY_RATES ] (state, data) {
            this._vm.$set(state, 'currencyConversionRates', data)
        },
        [ SET_TEMPORARY_SHOP ] (state, data) {
            this._vm.$set(state, 'temporaryShop', data)
        },
        [ SET_VAT_PROFILES ] (state, data) {
            this._vm.$set(state, 'applicationVatProfiles', data)
        },
    },
    actions: {
        initShop ({ commit }) {
            if (this._vm.$cookies.get(CONFIG.COOKIES.SHOP.KEY)) {
                commit(SET_SHOP, this._vm.$cookies.get(CONFIG.COOKIES.SHOP.KEY))
            }

            this._vm.$api.setShop(JSON.stringify(this._vm.$cookies.get(CONFIG.COOKIES.SHOP.KEY)))
        },

        setShop ({ commit, dispatch }, shop) {
            this._vm.$cookies.set(CONFIG.COOKIES.SHOP.KEY, shop)
            commit(SET_SHOP, shop)
            this._vm.$api.setShop(JSON.stringify(this._vm.$cookies.get(CONFIG.COOKIES.SHOP.KEY)))
            dispatch('fetchApplicationVatProfiles', shop)
        },

        setShopById ({ dispatch, state }, id) {
            const shop = state.shops.find((e) => e.applicationId === id)
            dispatch('setShop', shop)
        },

        async fetchShops ({ commit, dispatch, rootState }) {
            dispatch('loading/addJob', SET_SHOPS, {
                root: true,
            })
            const url = CONFIG.API.ROUTES.APPLICATIONS.GET_ALLOWED.replace('{accountId}', rootState.auth.user.accountId)
            await this._vm.$api.get(url).then(({ data }) => {
                data.data.sort((shopA, shopB) => shopA.applicationName.localeCompare(shopB.applicationName))
                commit(SET_SHOPS, data.data)
                dispatch('fetchAllowedActions')
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_SHOPS, {
                    root: true,
                })
            })
        },

        async fetchAllowedActions ({ commit, dispatch, rootState }) {
            dispatch('loading/addJob', SET_SHOPS, {
                root: true,
            })
            const url = CONFIG.API.ROUTES.ACTIONS.GET_ALLOWED.replace('{accountId}', rootState.auth.user.accountId)
            await this._vm.$api.get(url).then(({ data }) => {
                commit(SET_ALLOWED_ACTIONS, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_SHOPS, {
                    root: true,
                })
            })
        },

        async fetchCurrencyConversionRates ({ commit, dispatch, state }) {
            if (state.currencyConversionRates) {
                return
            }

            dispatch('loading/addJob', SET_CURRENCY_RATES, {
                root: true,
            })
            const url = CONFIG.API.ROUTES.CARRIERS.GET_CURRENCY_CONVERSION_RATES
            await this._vm.$api.get(url).then(({ data }) => {
                commit(SET_CURRENCY_RATES, data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                dispatch('loading/finishJob', SET_CURRENCY_RATES, {
                    root: true,
                })
            })
        },

        async fetchApplicationVatProfiles ({ state, dispatch, commit }, shop) {
            let applicationId = ''
            if (shop) {
                applicationId = shop.applicationId === '0' ? shop.entityApplicationId : shop.applicationId
            } else {
                applicationId = state.selectedShop.applicationId === '0' ? state.selectedShop.entityApplicationId : state.selectedShop.applicationId
            }

            if (!applicationId) {
                return
            }

            dispatch('loading/addJob', SET_VAT_PROFILES, {
                root: true,
            })

            const url = CONFIG.API.ROUTES.VAT_CONFIG.VAT_PROFILES.LIST_FOR_APPLICATION.replace('{applicationId}', applicationId)
            this._vm.$api.get(url).then(({ data }) => {
                commit(SET_VAT_PROFILES, data.data)
            }).finally(() => {
                dispatch('loading/finishJob', SET_VAT_PROFILES, {
                    root: true,
                })
            })
        },

        clearState ({ commit }) {
            commit(SET_SHOPS, [])
            commit(SET_SHOP, null)
            commit(SET_ALLOWED_ACTIONS, null)
            commit(SET_VAT_PROFILES, null)
        },

        checkShop ({ commit, state, dispatch }, id) {
            if (id === state.selectedShop.applicationId) {
                return
            }
            let payload
            state.shops.forEach((item) => {
                if (item.applicationId === id) {
                    payload = JSON.parse(JSON.stringify(item))
                }
            })
            if (state.selectedShop.applicationId === '0') {
                commit(SET_SHOP, { ...payload, ...state.selectedShop, entityApplicationId: payload.applicationId, entityApplicationName: payload.applicationName, anonymousAccountId: payload.anonymousAccountId })
                this._vm.$api.setShop(JSON.stringify(state.selectedShop))
                dispatch('fetchApplicationVatProfiles', state.selectedShop)
            } else {
                dispatch('setShop', payload)
            }
        },

        setTemporaryShop ({ commit, dispatch }, shop) {
            commit(SET_TEMPORARY_SHOP, shop)
            dispatch('fetchApplicationVatProfiles', shop)
        },
    },
    getters: {
        shopCurrency (state) {
            if (!state.selectedShop || !state.selectedShop.configuration || !state.selectedShop.configuration.workingCountries) {
                return 'EUR'
            }
            return Object.values(state.selectedShop.configuration.workingCountries)[0].currency
        },
        isBundled (state) {
            if (state.selectedShop.configuration && state.selectedShop.configuration.shopconfig && state.selectedShop.configuration.shopconfig.isCatalogBundled) {
                return state.selectedShop.configuration.shopconfig.isCatalogBundled === 'true'
            }
            return false
        },
        calculatedCurrency (state) {
            if (!state.selectedShop || !state.selectedShop.configuration || !state.selectedShop.configuration.workingCountries) {
                return 'EUR'
            }

            return country => state.selectedShop.configuration.workingCountries[country].currency
        },
        workingCountriesList (state) {
            if (!state.selectedShop || !state.selectedShop.configuration || !state.selectedShop.configuration.workingCountries) {
                return {}
            }

            return state.selectedShop.configuration.workingCountries
        },
    },
}

<template>
    <form @submit.prevent="submitForm">
        <div class="c-global-search">
            <mercur-button class="btn btn-icon" type="submit"><i class="fas fa-search"></i></mercur-button>
            <mercur-input class="m-0" v-model="searchTerm" :clearable="false"></mercur-input>
            <mercur-select class="m-0" v-model="searchKey">
                <option value="customers">Customers</option>
            </mercur-select>
        </div>
    </form>
</template>

<script>

export default {
    name: 'GlobalSearch',
    data () {
        return {
            searchTerm: '',
            searchKey: '',
        }
    },
    methods: {
        submitForm () {
            if (this.searchTerm === '') {
                this.$root.$emit('notification:global', {
                    message: 'Missing search term',
                })
                return
            }

            if (this.searchKey === '') {
                this.$root.$emit('notification:global', {
                    message: 'Missing search category',
                })
                return
            }

            if (this.searchTerm.length < 3) {
                this.$root.$emit('notification:global', {
                    message: 'Minimum length for search is 3 characters',
                })
                return
            }

            this.$router.push({
                name: 'SearchResultsView',
                query: {
                    searchTerm: this.searchTerm,
                    searchKey: this.searchKey,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-global-search {
        background-color: rgba(216,216,216,.22);
        max-width: 300px;
        max-height: 40px;
        margin: 0 0 0 100px !important;
        border-radius: 3px;
        padding: 0 10px !important;
        min-height: 40px !important;
        display: flex;
        align-items: center;
        position: relative;

        button {
            margin-left: -10px !important;
            margin-top: 0;
            margin-right: 0;

            &:hover {
                background-color: transparent;
            }
        }

        /deep/ input.form-input, /deep/ select.form-input {
            background-color: transparent;
            -webkit-text-fill-color: white !important;
            height: 24px !important;
            font-size: 15px !important;
            border: none;
            padding: 0;

            &:focus {
                border: none;
                outline: none;
            }
        }

        /deep/ select.form-input {
            text-indent: 1px;
            min-width: 95px;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: linear-gradient(45deg, transparent 50%, white 50%), linear-gradient(135deg, white 50%, transparent 50%);
            background-position: calc(100% - 10px) calc(1em + -4px), calc(100% - 5px) calc(1em + -4px), calc(100% - 2.5em) 2.5em;
            background-size: 5px 5px, 5px 5px, 1px 1.5em;
            background-repeat: no-repeat;
        }

        /deep/ input select {
            font-size: 13px !important;
        }

        /deep/ i {
            margin-top: 1px;
            color: white !important;
        }
    }
</style>

<script>
export default {
    name: 'v-title',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    watch: {
        title: {
            immediate: true,
            handler () {
                document.title = `${this.title} | Merchant Central`
            },
        },
    },
    render () {
        return false
    },
}
</script>

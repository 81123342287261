<template>
    <div class="c-title">
        <div class="container-fluid">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Title',
}
</script>

<style lang="scss" scoped>
    .c-title {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
        background: white;
        min-height: 80px;
        margin: -54px -15px 0 -15px;
        padding: 54px 15px 30px;

        &__button {
            color: black;
            background-color: #D8D8D8;
            border: 1px solid #979797;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            width: 31px;
            height: 31px;
            cursor: pointer;
            margin-left: 10px;
            margin-top: 8px;
            text-align: center;
            align-items: center;
            display: flex;

            &:hover {
                text-decoration: none;
            }

            &--back {
                margin-right: 15px;
                margin-left: 0;
            }

            &--disabled {
                opacity: .5;
            }
        }

        &__navigation {
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }

        p {
            margin: 0;
            color: #666666;
            font-size: 12px;
        }

        h1 {
            margin-top: 5px;
        }
    }
</style>

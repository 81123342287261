import CONFIG from '@root/config'

export default {
    namespaced: true,
    actions: {
        initLocale () {
            if (!this._vm.$cookies.get(CONFIG.COOKIES.LOCALE.KEY)) {
                this._vm.$cookies.set(CONFIG.COOKIES.LOCALE.KEY, 'en-GB')
            }

            this._vm.$api.setLocale(this._vm.$cookies.get(CONFIG.COOKIES.LOCALE.KEY))
        },
    },
}
